import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import jcf from "jcf/js/jcf";
import visibleIf from "../utils/visibleIf";

const VisibleIf = ({name, value, children}) => {
    const wizardForm = useSelector(state => state.form.wizard);

    jcf.setOptions('Select', {
        wrapNative: false,
        wrapNativeOnMobile: false,
        fakeDropInBody: true,
    });
    jcf.replaceAll();

    return visibleIf(name, value, wizardForm) ? children : null;
};

export default React.memo(VisibleIf);