import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({text, extraCss = ''}) => {
    const [visible, setVisible] = useState(false);

    return (
        <a className={`tooltip-opener icon-help ${visible ? 'hover' : ''} ${extraCss}`} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
        <span className="tooltip-drop">
            <span className="tooltip-wrap">
                <span className="text">{text}</span>
            </span>
        </span>
        </a>
    );
}

Tooltip.propTypes = {
    text: PropTypes.string.isRequired
};

export default Tooltip;
