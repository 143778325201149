import React from 'react';
import {DropdownDate} from 'react-dropdown-date';
import moment from 'moment';
import is from 'is_js';

const Date3 = props => {
    let selectedDate = null;
    if ((props.input.value !== '') && (props.input.value!== null)) {
        selectedDate = moment(props.input.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    console.log('[Date3] init', props.input.value, selectedDate);

    const handleChange = (value) => {
        console.log('    [Date3]     handleChange', value);
        let strDate = null;
        if (value !== null) {
            strDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        console.log('    [Date3]     handleChange', strDate);
        props.input.onChange(strDate);
    }

    return (
        <DropdownDate
            onDateChange={handleChange}
            order={                             // optional
                ['day', 'month', 'year']        // Order of the dropdowns
            }
            options={                           // optional
                {
                    yearReverse: true,              // false by default
                    monthShort: true,               // false by default
                }
            }
            selectedDate={selectedDate}


            defaultValues={                     // optional
                {
                    year: 'year',
                    month: 'month',
                    day: 'day'
                }
            }
        />
    )
}

export default Date3;