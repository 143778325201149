import React, {useEffect} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const Input = ({itemCols, name, value, placeholder = '', setError, disabled = false}) => {
    const error = useSelector(state => state.customValidationErrors.errors[name]);
    useEffect(() => {
        setError(!!error);
    }, [error])
    // console.log('[Input]', name, error);
    return (
        <div className={itemCols}>
            {error && <div className="input-error2">{error}</div>}
            <Field component="input"
                   type="text"
                   id={name}
                   name={name}
                   disabled={disabled}
                   className="form-control form-control-sm"
                   value={value}
                   placeholder={placeholder}
                   autoComplete={Math.random().toString(36).substring(2, 15)}
            />
        </div>
    );
};

export default Input;