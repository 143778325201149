import React, {useEffect} from 'react';
import axios from 'axios'
import {useSelector, useDispatch} from "react-redux";
import { useParams } from 'react-router-dom';

import Loading from "../../components/Loading";
import * as actionTypes from '../../store/actions/actionTypes';
import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Message from "../../components/Message";
import AdmissionMessage from "../../components/AdmissionMessage/AdmissionMessage";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import Theme_Footer from "../../components/Theme/Theme_Footer";
import {useAuth} from "../../utils/auth";

const AdmissionMessagePage = () => {
    const message = useSelector(state => state.main.currentMessage);
    const loadingError = useSelector(state => state.main.loadingError);
    const {getAxiosAuthConfig} = useAuth();
    const {hash} = useParams();
    console.log('[AdmissionMessagePage] ', hash);
    const dispatch = useDispatch();

    const fetchData = async () =>  {
        console.log('[AdmissionMessagePage] fetchData()');
        axios.get(`/api/patient/admission/message/${hash}`,getAxiosAuthConfig())
            .then(async (response) => {
                if (response.data.message) {
                    console.log('[AdmissionMessagePage] Got document data', response.data.message);
                    await dispatch({type: actionTypes.SET_ADMISSION_MESSAGE, message: response.data.message});
                } else {
                    await dispatch({type: actionTypes.SET_DOCUMENT_LOADING_ERROR, error: response.data.error});
                    console.log('[AdmissionMessagePage] Got error', response.data);
                }
            })
    };

    useEffect(() => {
        fetchData()
    }, []);

    const renderDocument = () => {
        if (loadingError) {
            return <Message message={loadingError} />;
        } else if (message) {
            return <AdmissionMessage message={message}/>;
        } else {
            return <Loading />;
        }
    }

    return (
        <div id="wrapper">
            <header className="header sticky-header" id="document_header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>
            {renderDocument()}
            <Theme_Footer />
        </div>
    );
}

export default AdmissionMessagePage;