import React from 'react';
import Moment from 'moment';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PropTypes from 'prop-types';
import {Field} from "redux-form";

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        if (this.myRef.current.classList.contains('hasDatePicker')) return;

        $(this.myRef.current).datepicker(
            {
                dateFormat: 'dd/mm/yy',
                onSelect: (value) => this.onChange(value ),
            }
        );
    }

    onChange = (value) => this.props.input.onChange(value);

    render() {
        const {input, placeholder, meta, label, ...rest} = this.props;

        const className = rest.className ? rest.className : "form-control form-control-sm datepicker";
        return (
            <div className={`row no-gutters ${meta.touched && meta.error && "form-error"}`}>
                <div className="col-12 col-lg-6">
                    <label htmlFor={input.name} className="m-0">{label}</label>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 pl-lg-4">
                    <input className={className}
                           type="text"
                           id={input.name}
                           placeholder="DD/MM/YYYY"
                           ref={this.myRef}
                           name={input.name}
                           onChange={event => event.preventDefault()}
                           value={input.value}
                           autoComplete="off"
                    />
                </div>
            </div>
       );
    }
}

DatePicker.propTypes = {
    input: PropTypes.object,
    itemCols: PropTypes.string,
    placeholder: PropTypes.string,
};

export default DatePicker;