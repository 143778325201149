import {forEach, size, keys} from 'underscore';
import dotProp from 'dot-prop-immutable';

import {isVisible} from "./visibleIf";
import * as actionTypes from "../../../store/actions/actionTypes";

export const reviewBackendValidation_wasFailed = (documentSystemData) => {
    return documentSystemData && documentSystemData.reviewBackendValidations && !documentSystemData.reviewBackendValidations.valid;
}

export const reviewBackendValidation_firstErrorStep = (document, values) => {
    if (reviewBackendValidation_wasFailed(document.systemData)) {
        const error = reviewBackendValidation_validateErrors(document, values);
        return error.valid ? false : error.firstErrorStep;
    }
    return false; // this value should not be used, if valid = true
}

export const reviewBackendValidation_validateErrors = (document, values) => {
    // console.log('[reviewBackendValidation] validateErrors', document.systemData);
    let res = {
        "valid": true,
        "firstErrorStep": 'ACA-93-1', // this is debug marker for wrong data structure/data handling
        "errors" : [],
    };
    if (size(values) === 0) {
        // console.error('[reviewBackendValidation] no values!', values);
        return res; // default value
    }
    if (reviewBackendValidation_wasFailed(document.systemData)) {
        document.systemData.reviewBackendValidations.errors.map(_error => {
            let addError = true;
            // console.log('[reviewBackendValidation] errors.map', _error);
            forEach(_error.values, ((_value, key) => {
                // console.log('[reviewBackendValidation] forEach', key, _value, dotProp.get(values, key, ''));
                if (dotProp.get(values, key, '') !== _value) {
                    // console.log('[reviewBackendValidation] addError = false, wrong value', key);
                    addError = false;
                }
                if (addError && !isVisible(key, values, document.template)) {
                    // console.log('[reviewBackendValidation] addError = false, !isVisible', key);
                    addError = false;
                }
            }));
            if (addError) {
                res.firstErrorStep = res.valid ? _error.step : Math.min(_error.step, res.firstErrorStep);
                res.valid = false;
                res.errors.push(_error);
            }
        });
        return res;
    }

    return res;
}

export const reviewBackendValidation_onSubmit = (currentStep, currentDocument, values, defaultValidState, dispatch) => {
    console.log('[reviewBackendValidation] onSubmit', currentStep, currentDocument, values, defaultValidState);
    const documentSystemData = currentDocument.systemData;
    if (!documentSystemData.reviewBackendValidations || documentSystemData.reviewBackendValidations.valid) {
        return defaultValidState;
    }

    const actualErrors = reviewBackendValidation_validateErrors(currentDocument, values);

    if (actualErrors.valid) {
        return defaultValidState;
    }
    console.log('[reviewBackendValidation] onSubmit actualErrors', actualErrors);
    reviewBackendValidation_dispatchCustomErrors(dispatch, actualErrors);

    if (actualErrors.firstErrorStep !== currentStep) {
        return defaultValidState;
    }

    return false;
}

export const reviewBackendValidation_dispatchCustomErrors = (dispatch, errors) => {
    if (errors.valid) {
        return false;
    }
    console.log('[reviewBackendValidation] dispatchCustomErrors', errors);
    errors.errors.map(_i => {
        dispatch({type: actionTypes.CUSTOM_VALIDATION_SET_COMPLEX_ERROR, keys: keys(_i.values), error: _i.error })
    });

    return true;
}