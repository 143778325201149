import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change} from "redux-form";

const MedicationAdd = (props) => {
    const form = useSelector(state => state.form);
    const dispatch = useDispatch();

    const rowsCount = () => form.wizard.values[props.target] || 3;

    const clickHandler = (e) => {
        e.preventDefault();
        dispatch(change('wizard', props.target, rowsCount()+1));
    };

    return (
        <>
            <div className="col-12 px-3 py-2 px-lg-2">
                <a href="#" className="btn btn-secondary d-block w-100" onClick={clickHandler}><i className="icon icon-plus" /> {props.btnLabel || 'Add row'}</a>
            </div>
        </>
    );
};

export default MedicationAdd;