export const validateMedicare = (medicare) => {
    let isValid = false;

    if (medicare && medicare.length === 10) {
        const matches = medicare.match(/^([2-6]\d{7})(\d)([1-9])$/);

        if (!matches) {
            return false;
        }

        const base = matches[1];
        const checkDigit = matches[2];
        const weights = [1, 3, 7, 9, 1, 3, 7, 9];

        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            sum += parseInt(base[i], 10) * weights[i];
        }

        isValid = sum % 10 === parseInt(checkDigit, 10);
        if (!isValid) {
            console.log(medicare, sum % 10);
        }
    }

    return isValid;
}