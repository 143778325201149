import React from 'react';
import {Field} from 'redux-form';

import FormLabel from '../Label';
import FormInput from '../Input';
import FormSelect from '../Select';

const DVACardColour = (props) => {
    const cardColours = [
        {value: '', label: 'Please select'},
        {value: 'Gold', label: 'Gold'},
        {value: 'White', label: 'White'},
        {value: 'Orange Expiry', label: 'Orange'},
    ];

    return (
        <>
            <FormLabel htmlFor="DVACardColour"
                       label="DVA Card Colour"
                       labelCols="col-12 col-lg-4"/>

            <div className="col-12 col-lg-8">
                <div className="row ">
                    <FormSelect itemCols="col-12 col-lg-7 col-xl-8"
                                name="DVACardColour"
                                options={cardColours}
                    />
                    <div className="col-12 col-lg-5 col-xl-4 border-left">
                        <div className="row no-gutters ">
                            <FormLabel htmlFor="DVACard_expiry"
                                       label="Expiry"
                                       labelCols="col-12 col-lg-7"/>
                            <FormInput {...props}
                                       itemCols="col-12 col-lg-5"
                                       name="DVACard_expiry"
                                       placeholder="MM/YY"/>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}


export default DVACardColour;