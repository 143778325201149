import visibleIf from "./visibleIf";
import dotProp from 'dot-prop-immutable';
import PubSub from "pubsub-js";
import * as pubSubMessages from "../store/pubSubMessages";
import * as actionTypes from "../../../store/actions/actionTypes";
import {validateMedicare} from "./medicare";

export const customValidationForm = (formStructure, values, valid, dispatch) => {
    let result = valid;
    let shouldScroll = valid;
    const setError = (name, errorText = '') => {
        result = false;
        PubSub.publish(pubSubMessages.VALIDATION_ERROR_SET, name);
        if (shouldScroll) {
            PubSub.publish(pubSubMessages.VALIDATION_ERROR_SCROLL, name);
        }
        if (errorText) {
            // new way to handle errors. Probably, pubSub could be deprecated sometime
            dispatch({type: actionTypes.CUSTOM_VALIDATION_SET_ERROR, variable: name, error: errorText});
        }
        shouldScroll = false;
    }

    const clearError = (name) => {
        PubSub.publish(pubSubMessages.VALIDATION_ERROR_CLEAR, name);
        dispatch({type: actionTypes.CUSTOM_VALIDATION_SET_ERROR, variable: name, error: false});
    }

    formStructure.cards.map((card) => {
        if (!card.visibleIf || visibleIf(card.visibleIf.name, card.visibleIf.value, values)) {
            card.rows.map((row) => {
                if (!row.visibleIf || visibleIf(row.visibleIf.name, row.visibleIf.value, values)) {
                    row.items.forEach((item) => {
// Custom List validation
                        if ((item.type === 'CustomList') && item.required) {
                            if (customListValidation(item, values)) {
                                clearError(`${item.namePrefix}_count`)
                            } else {
                                console.log('[customValidation] CustomList error');
                                setError(`${item.namePrefix}_count`)
                            }
                        }

                        if(!item.customValidation || !dotProp.get(values.values, item.name)) {
                            // ACA-21 - we do not validate empty values - required prop used for that
                            return false;
                        }

                        const _err = customValidationItemErrors(item.customValidation, dotProp.get(values.values, item.name));
                        console.log('[custom]', item, _err);
                        if (!_err.length) {
                            clearError(item.name)
                        } else {
                            console.log('[customValidation]', item.customValidation, 'Invalid');
                            setError(item.name, _err[0]);
                        }
                    });
                }
            });
        }
    });
    return result;
}

const customValidationItemErrors = (validationTypes, itemValue) => {
    let results = [];
    validationTypes.forEach(i => {
        console.log('[custom]', itemValue, i);
        switch (i) {
            case "10-digits":
                if (itemValue && !/^\d{10}$/.test(itemValue)) {
                    results.push('Value should be 10 digits number');
                }
                break;
            case "2-digits":
                if (itemValue && !/^\d{2}$/.test(itemValue)) {
                    results.push('Value should be 2 digits number');
                }
                break;
            case "medicare":
                if (itemValue && !validateMedicare(itemValue)) {
                    results.push('Invalid Medicare number');
                }
                break;
        }
    })

    return results;
}

export const customListValidation = (item, values) => {
    // console.log('[CustomList]', `${item.namePrefix}_count`, dotProp.get(values.values, `${item.namePrefix}_count`));
    let haveCompleteRow = false;
    for (let i = 0;i<dotProp.get(values.values, `${item.namePrefix}_count`);i++) {
        let completeRow = true;
        let emptyRow = true;
        item.columns.map(column => {
            if (
                !dotProp.get(values.values, `${item.namePrefix}${column.namePrefix}${i}`)
            ) {
                if (column.required !== false) {
                    // console.log('[CustomList] Empty ',column.namePrefix, i );
                    completeRow = false;
                }
            } else {
                emptyRow = false;
            }
        });
        if (completeRow) {
            haveCompleteRow = true;
        }
        if (!emptyRow && !completeRow) {
            return false;
        }
        // console.log('[CustomList] ', haveCompleteRow)
    }
    return haveCompleteRow;
}