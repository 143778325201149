import React from 'react';
import {Field} from 'redux-form';
import ReduxSelectAsync from "./ReduxComponent/ReduxSelectAsync";

const SelectAsync = ({name, itemCols, options, placeholder = ''}) => {
    return (
        <div className={itemCols}>
            <Field className="sm" name={name} component={ReduxSelectAsync} options={options} placeholder={placeholder} />
        </div>
    );
};

export default SelectAsync;