import React from "react";
import {useSelector} from "react-redux";

const Theme_WelcomeText = ({name}) => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    if ((loadedThemeId !== false) && name) {
        const text = theme.patientPortal_welcomeTextHtml.replace('{name}', name);
        return (
            <div className="signin-text" dangerouslySetInnerHTML={{__html: text}}/>
        )
    }
    return null;
}
export default Theme_WelcomeText;