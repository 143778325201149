import React, {useState} from "react";
import PropTypes from 'prop-types';
import {isDirty, submit} from 'redux-form';
import {connect, useSelector} from 'react-redux';
import {useMediaQuery} from "react-responsive";
import ProgressPanelMobile from "../ProgressPanel/ProgressPanelMobile";
import {Sticky, StickyContainer} from "react-sticky";
import * as wizardActionCreators from "../store/actions/wizard";
import {useAuth} from "../../../utils/auth";

const SubHeader = ({step, title, subTitle='', dirty, dispatch, onNextStep}) => {
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const {getPatientPortalUrlPrefix} = useAuth();

    const handleSave = () => dispatch(wizardActionCreators.wizardSave(`/${getPatientPortalUrlPrefix()}/tasks`));

    const isSidebarInMobileMode = useMediaQuery({ maxDeviceWidth: 768 });

    return (
            <Sticky>
                {
                    ({style, isSticky}) => {
                        // console.log("style", style);
                        // if (isSticky) {
                        //     style = {...style, top: "20px"};
                        // }

                        return (
                            <header className={`step-header ${submenuOpen && 'step-active'}`} style={style}>
                                <div
                                    className="row d-flex flex-nowrap flex-row align-items-start align-items-md-center">
                                    <div className="heading-holder col-9">
                                        <strong className="h3 m-0">
                                            <span className="number">{step}</span>
                                            <a className="step-opener" tabIndex="1"
                                               onClick={() => setSubmenuOpen(!submenuOpen)}>{title}</a>
                                        </strong>
                                        {subTitle && <span className="active-title title-holder" dangerouslySetInnerHTML={{__html: subTitle}}/>}
                                    </div>
                                    <div className="btn-holder col-3 d-flex justify-content-end align-items-center">
                                        <a href="#" className="btn btn-primary btn-rounded mw-100" onClick={handleSave}>
                                            <i className="icon icon-save d-md-none m-0"/>
                                            <span className="d-none d-md-inline">Save for Later</span>
                                        </a>
                                    </div>
                                </div>
                                <div id="insert-from">
                                    {submenuOpen && isSidebarInMobileMode && <ProgressPanelMobile onNextStep={onNextStep}/>}
                                </div>
                            </header>
                        );
                    }
                }
            </Sticky>
    );
}

SubHeader.propTypes = {
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    dirty: isDirty('wizard')(state)
});

export default connect(mapStateToProps)(SubHeader);