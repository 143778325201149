import React from "react";

const Tasks_CutoffBlock = ({tasks}) => {
    const renderTask = task =>
        <li key={task.hash}>
            <a href='#' className="tasks-item cursor-default">
                {task.title}
                <span className="icon icon-arrow-right" />
            </a>
        </li>
    ;


    if (tasks.length) {
        return (<section className="tasks-block">
            <div className="head-block head-simple">
                <h2>Expired Tasks</h2>
                <span className="counter">{tasks.length}</span>
            </div>

            <ul className="tasks list-unstyled">
                {tasks.map(renderTask)}
            </ul> :
            {/*<span className="tasks-text">You have no completed tasks</span>*/}
        </section>
        );
    }
    return null;
}

export default Tasks_CutoffBlock;