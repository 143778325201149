import React from 'react';
import {Field} from "redux-form";
import PropTypes from 'prop-types';
import * as ComplexPropTypes from '../../complexPropTypes';
import {optionId} from "../../../../utils/utils";

const Checkboxes = ({itemCols, extraUlStyles, value, name, options }) => {
    return (
        <div className={itemCols}>
            <ul className={`check-list list-unstyled d-flex flex-wrap flex-row ${extraUlStyles || ''}`}>
                {options.map((option, index) => (
                    <li key={index}>
                        <div className="custom-control custom-checkbox">
                            <Field component="input"
                                   type="checkbox"
                                   className="custom-control-input"
                                   id={`check_${name}_${optionId(option)}`}
                                   name={`${name}[${option.value}]`}/>
                            <label className="custom-control-label p-0 m-0"
                                   htmlFor={`check_${name}_${optionId(option)}`}>
                                {option.label}
                            </label>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

Checkboxes.propTypes = {
    itemCols: PropTypes.string.isRequired,
    extraUlStyles: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: ComplexPropTypes.optionsArray.isRequired
};

export default Checkboxes;