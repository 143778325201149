import React from 'react';
import {Field} from 'redux-form';

const renderCheckbox = ({input, label, placeholder, meta}) => (
    <li className={`${meta.touched && meta.error && "form-error"}`}>
        <div className={`custom-control custom-checkbox`}>
            <Field component="input"
                   type="checkbox"
                   id={input.name}
                   name={input.name}
                   className="custom-control-input"
            />
            <label className="custom-control-label p-0 m-0" htmlFor={input.name} dangerouslySetInnerHTML={{__html: label}}/>
        </div>
    </li>
);

const AgreeCheckboxes = ({items}) => {
    const required = value => value ? undefined : 'Required'

    const renderItem = (item, key) => {
        if (item.name) {
            return <Field key={key} {...item} component={renderCheckbox}  validate={item.required ? [required] :[] }/>
        } else {
            return (
                <li key={key}>
                    <div>
                        <div className="text-row" dangerouslySetInnerHTML={{__html: item.label}} />
                    </div>
                </li>
            );
        }
    }

    return (
        <div className="row no-gutters">
            <div className="col-12 checks-block">
                <ul className="check-list list-unstyled">
                    {items.map(renderItem)}
                </ul>
            </div>
        </div>
    );
};

export default AgreeCheckboxes;