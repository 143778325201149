import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { push } from '@lagunovsky/redux-react-router';
import {useAuth} from "../../utils/auth";

const Theme_Logo = ({onClick = false}) => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);
    const userAdmission = useSelector(state => state.main.userAdmission);
    const dispatch = useDispatch();
    const {getPatientPortalUrlPrefix} = useAuth();

    const goToTasksList = (event) => {
        event.preventDefault();
        if (onClick) {
            onClick(event);
        } else if (userAdmission) {
            dispatch(push(`/${getPatientPortalUrlPrefix()}/tasks`));
        }
    }

    if (loadedThemeId) {
        return (
            <div className={theme.class_logo} onClick={goToTasksList} style={{cursor: 'pointer'}}>
                <img src={`${theme.cdn_path}${theme.file_logo}`} alt={theme.patientPortal_logoAlt}/>
            </div>
        );
    }
    return null;
}

export default Theme_Logo;