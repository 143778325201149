import React, {Component} from 'react';
import {Field} from 'redux-form';
import { connect } from 'react-redux'

import PubSub from "pubsub-js";
import * as pubSubMessages from "../../../store/pubSubMessages";

class BodyMassIndex extends Component {
    state = {
        height: this.props.height, weight: this.props.weight
    };

    bodyIndex = () => {
        if (this.state.height && this.state.weight) {
            return Math.round(100000*this.state.weight / (this.state.height*this.state.height))/10;
        }
        return '';
    }

    render = () => {
        return (
            <>
                <div className="col-12 col-lg-5">
                    <label htmlFor="bodyMass_height" className="m-lg-0">Your height{this.props.required ? <sup>*</sup> : ''}</label>
                </div>
                <div className="col-12 col-lg-1">
                    <Field id="bodyMass_height"
                           autoComplete="off"
                           component="input"
                           type="text"
                           name="bodyMass_height"
                           className="form-control form-control-sm"
                           placeholder="cm"
                           onChange={ (event, newValue) => this.setState({height: newValue})}/>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="row no-gutters ">
                        <div className="col-12 col-lg-6 border-left">
                            <div className="row no-gutters ">
                                <div className="col-12 col-lg-9">
                                    <label htmlFor="weight1" className="m-lg-0">Weight{this.props.required ? <sup>*</sup> : ''}</label>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <Field id="bodyMass_weight"
                                           autoComplete="off"
                                           component="input"
                                           type="text"
                                           name="bodyMass_weight"
                                           className="form-control form-control-sm"
                                           placeholder="kg"
                                           onChange={ (event, newValue) => this.setState({weight: newValue})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 border-left">
                            <div className="row no-gutters ">
                                <div className="col-12 col-lg-9">
                                    <label htmlFor="body1" className="m-lg-0">Body Mass Index</label>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <input id="body1" type="text" className="form-control form-control-sm"
                                           placeholder=". . ." disabled={true} value={this.bodyIndex()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        height: state.form.wizard.values.bodyMass_height,
        weight: state.form.wizard.values.bodyMass_weight
    }
}

export default connect(mapStateToProps)(BodyMassIndex);