import axios from "axios";
import {push} from '@lagunovsky/redux-react-router';
import {destroy} from "redux-form";

import * as actionTypes from "./actionTypes";
import {reviewBackendValidation_firstErrorStep} from "../../components/Document_v2/utils/reviewBackendValidation";
import {useAuthThunk} from "../../utils/auth";

export const init = (documentHash) => {
    return (dispatch, getState) => {
        dispatch(destroy('wizard'));
        dispatch({type: actionTypes.UNSET_DOCUMENT});
        const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuthThunk(getState);

        //  TODO: ACA-174 implement impersonate
        axios.get(`/api/patient/admission/document/${documentHash}/v2/review`, getAxiosAuthConfig())
            .then((response) => {
                const firstErrorStep = reviewBackendValidation_firstErrorStep(response.data.document, response.data.document.documentData);

                if (firstErrorStep) {
                    console.log('wizardInitReview - firstErrorStep=',firstErrorStep, response.data.document, response.data.documentData)
                    // dispatch({type: actionTypes.SET_DOCUMENT, document: response.data.document});
                    // dispatch({type : actionTypes.LOADING_DONE});
                    dispatch(push(`/${getPatientPortalUrlPrefix()}/document/2/${documentHash}/${firstErrorStep}`));
                } else {
                    if (response.data.document.yamlTemplate_version !== 2) {
                        console.error('wrong yamlTemplate_version=', response.data.document.yamlTemplate_version);
                        dispatch(push('/'));
                    } else {
                        // TODO: implement and cover with tests aca109Test()

                        dispatch({
                            type: actionTypes.REVIEW_INIT,
                            admissionType: response.data.document.template,
                            vars: response.data.document.documentData,
                            documentHash: documentHash,
                            files: response.data.document.files,
                            hospital: response.data.hospitalConfig,
                            admissionDocumentType: response.data.document.admissionDocumentType,
                        });
                        dispatch({
                            type: actionTypes.PROGRESS_PANEL_INIT,
                            steps: response.data.document.template.steps.map(item => item.title),
                            currentStep: -1,
                            allStepsEnabled: true,
                            documentHash: documentHash
                        })
                    }
                }
                // if (response.data.status < 3) {
                // } else {
                //     // TODO: log hack attempt
                //     dispatch(push('/'));
                // }
            })
        ;
    }
};

export const sign = (values) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.DOCUMENT_PROCESSING_START})
        const userAdmission = getState().main.userAdmission;
        const documentHash = getState().review.documentHash;
        const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuthThunk(getState);

        //  TODO: ACA-174 implement impersonate
        axios.post(`/api/patient/admission/document/${documentHash}/v2/sign`, values, getAxiosAuthConfig())
            .then((response) => {
                dispatch({type: actionTypes.DOCUMENT_PROCESSING_DONE});
                dispatch(push(`/${getPatientPortalUrlPrefix()}/tasks`));
            })
        ;
    }
};