import React, {useRef, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import * as actionTypes from "../../store/actions/actionTypes";
import Review from "../../components/Review/Review";
import Loading from "../../components/Loading";
import * as reviewActionCreators from "../../store/actions/review";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Theme_Footer from "../../components/Theme/Theme_Footer";

const ReviewPage = () => {
    const dispatch = useDispatch();
    const {ready, documentHash} = useSelector(state => state.review);
    let paramsDocumentHash = useParams().documentHash;

    const formTopRef = useRef(null);
    const scrollToTop = () => formTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    useEffect(() => {
        // console.log('[ReviewView] useEffect[]', paramsDocumentHash);
        scrollToTop();
        dispatch(
            reviewActionCreators.init(paramsDocumentHash)
        );
    }, []);

    return (
        <div id="wrapper">
            <header className="header sticky-header" id="document_header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>
            <div ref={formTopRef}/>
            {(ready && (documentHash === paramsDocumentHash)) ? <Review/> : <Loading/>}
            <Theme_Footer />
        </div>
    );
};

export default ReviewPage;