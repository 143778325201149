import React, {Fragment} from 'react';
import Tooltip from "./Tooltip";

const label = (props) => {

    const renderHtmlFor = () => {
        let labelHtml = props.labelHtml;
        if (props.required) {
            labelHtml = labelHtml + "<sup>*</sup>";
        }
        return props.labelHtml ?
            (
                <label htmlFor={props.htmlFor} className="m-lg-0" dangerouslySetInnerHTML={{__html: labelHtml}} />
            )
                :
            (
                <label htmlFor={props.htmlFor} className="m-lg-0">{props.label}
                    {props.tooltip && <Tooltip text={props.tooltip}/>}
                    {props.required && <sup>*</sup>}
                </label>
            );
    }

    const renderSpan = () => {
        return props.labelHtml ?
            (
                <span className="label m-lg-0" dangerouslySetInnerHTML={{__html: props.labelHtml}} />
            ) :
            (
                <span className="label m-lg-0">{props.label}
                    {props.tooltip && <Tooltip text={props.tooltip}/>}
                </span>
            );
    }

    return (
        <div className={props.labelCols}>
            {props.htmlFor ? renderHtmlFor() : renderSpan()}
        </div>
    );
}

export default label;