import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

const JmphIfcBlock = ({key, template}) => {
    const documentData = useSelector(state => state.main.currentDocument.documentData);

    const nonZeroAmount = (amount) => {
        if (amount === '0.00') {
            return '-';
        } else {
            return `$${amount}`
        }
    }

    const renderIfcRow = (row, index) => {
        return (
            <div className="row w-100 no-gutters items-descr" key={index}>
                <div className="col-12 col-lg-5">
                    <span className="label m-lg-0">{row.item_desc}</span><br/>
                    {/*<span style={{marginLeft: '10px'}}></span>*/}
                </div>
                <div className="col-12 col-lg-7 pl-lg-2 items-list">
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Estimated Cost</span>
                        <span className="label ml-auto">{nonZeroAmount(row.est_cost)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Fund Rebate</span>
                        <span className="label ml-auto">{nonZeroAmount(row.fund_rebate)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Patient Cost per day</span>
                        <span className="label ml-auto">{nonZeroAmount(row.pat_cost_per_day)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Patient Cost max</span>
                        <span className="label ml-auto">{nonZeroAmount(row.est_total_patient)}</span>
                    </div>
                </div>
            </div>
        );
    }

    const renderIfcGroup = (key) => {
        return <>
            <div className="card-header">
                <h4 className="mb-0">{key}</h4>
            </div>
            {documentData.adm2_ifc___group.data[key].map(renderIfcRow)}
        </>
    }

    return (
        <div className="card mb-2 mb-lg-4" key={key}>
            <div className="card-header">
                <h3 className="mb-0">Estimated</h3>
            </div>
            <div className="card-body body-small">
                <div className="row no-gutters pb-0 pb-lg-4">
                    <div className="col-12">
                        <div className="row w-100 no-gutters d-none d-lg-flex items-names mt-2 mb-3">
                            <div className="col-5">
                                <span className="label m-lg-0">Description</span>
                            </div>
                            <div className="col-7 pl-2 items-list">
                                <div className="item d-flex">
                                    <span className="label ml-auto">Estimated Cost</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Fund Rebate</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Patient Cost per day</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Patient Cost max</span>
                                </div>
                            </div>
                        </div>

                        {Object.keys(documentData.adm2_ifc___group.data).map(renderIfcGroup)}
                    </div>

                </div>
                <div className="row no-gutters pt-3 pb-3 pb-lg-2">
                    <div className="col-12">
                        <div className="box">
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Charge</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">${documentData.adm2_ifc_total_estimatedCost}</span>
                                </div>
                            </div>
                            {/*<div className="row no-gutters justify-content-end">*/}
                            {/*    <div className="d-flex">*/}
                            {/*        <span className="label text-default m-lg-0">Benefit</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="d-flex value">*/}
                            {/*        <span className="label m-lg-0">${documentData.lifehouse_ifc.benefit}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row no-gutters justify-content-end">*/}
                            {/*    <div className="d-flex">*/}
                            {/*        <span className="label text-default m-lg-0">Out-of-Pocket</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="d-flex value">*/}
                            {/*        <strong className="label m-lg-0">${documentData.lifehouse_ifc.totalToBePaid}</strong>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JmphIfcBlock;