import React from 'react';
import {Field} from 'redux-form';
import FormLabel from '../Label';
import FormInput from '../Input';

const SuburbStatePostcode = (props) => (
    <>
        <FormLabel htmlFor={`${props.namePrefix}suburb`}
                   label="Suburb"
                   labelCols={props.labelCols} />

        <div className={props.itemCols}>
            <div className="row ">
                <FormInput {...props}
                           itemCols="col-12 col-lg-4"
                           name={`${props.namePrefix}suburb`}
                           placeholder="Suburb"
                />

                <div className="col-12 col-lg-4 border-left">
                    <div className="row no-gutters ">
                        <FormLabel htmlFor={`${props.namePrefix}state`}
                                   label="State"
                                   labelCols="col-12 col-lg-6" />
                        <FormInput {...props}
                                   itemCols="col-12 col-lg-6"
                                   name={`${props.namePrefix}state`}
                                   placeholder="State"/>
                    </div>
                </div>
                <div className="col-12 col-lg-4 border-left">
                    <div className="row no-gutters ">
                        <FormLabel htmlFor={`${props.namePrefix}postcode`}
                                   label="Postcode"
                                   labelCols="col-12 col-lg-6" />
                        <FormInput {...props}
                                   itemCols="col-12 col-lg-6"
                                   name={`${props.namePrefix}postcode`}
                                   placeholder="Postcode"/>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default SuburbStatePostcode;