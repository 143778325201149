import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store/configureStore';

import './assets/css/bootstrap.css';
import './assets/css/main.css';
import './assets/css/custom.css';
import * as actionTypes from "./store/actions/actionTypes";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://e0b224b6802740c98629227e322c2f18@sentry.admission.com.au/5"});

    const LogRocket = require('logrocket');
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
        console: {
            shouldAggregateConsoleErrors: true,
        }
    });

    const setupLogRocketReact = require('logrocket-react');
    setupLogRocketReact(LogRocket);
}

const store = configureStore();

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
const applyTheme = response => {
    const state = store.getState();
    const loadedThemeId = state.theme.loadedThemeId;
    if (response && response.data && response.data.theme && (!loadedThemeId || (loadedThemeId !== response.data.theme.id))) {
        console.log('Theme apply', loadedThemeId, response.data.theme.id);
        store.dispatch({type: actionTypes.SET_THEME_AND_HOSPITAL_CONFIG, hospitalConfig: response.data.hospitalConfig, theme: response.data.theme})
    }
    return response;
}

axios.interceptors.response.use(applyTheme);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
      <ReduxRouter history={history}>
        <App />
      </ReduxRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
