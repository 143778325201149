import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import ProgressPanelDesktop from "../../components/Document_v2/ProgressPanel/ProgressPanelDesktop";
import * as actionTypes from '../../store/actions/actionTypes';

const DevTestPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actionTypes.PROGRESS_PANEL_INIT,
            steps: ['Step1', 'Step2', 'Step3'],
            currentStep: 3,
            allStepsEnabled: true,
            documentHash: '8a7sdasdkasd'
        })
    }, []);

    return <ProgressPanelDesktop />
}

export default DevTestPage;