import React, {useEffect, useState} from 'react';
import jcf from 'jcf/js/jcf';
import {reduxForm} from 'redux-form';
import { Link, useParams } from 'react-router-dom';

import {connect, useDispatch} from 'react-redux';
import {useSelector} from "react-redux";
import {throttle} from 'underscore';
import _ from 'lodash';
import { withRouter } from './../../../utils/utils';

import parseRequiredFields from '../utils/parseRequiredFields';
import Card from "./Card/Card";
import SubHeader from "./SubHeader";
import PubSub from "pubsub-js";

import * as pubSubMessages from "../store/pubSubMessages";
import BackButton from "./Blocks/BackButton";
import * as wizardActionCreators from "../store/actions/wizard";
import VisibleIf from "./VisibleIf";
import {useMediaQuery} from "react-responsive";
import ProgressPanelDesktop from "../ProgressPanel/ProgressPanelDesktop";
import {StickyContainer} from "react-sticky";
import {customValidationForm} from "../utils/customValidation";
import DevCard from "./DevCard/DevCard";
import {reviewBackendValidation_onSubmit} from "../utils/reviewBackendValidation";
import {useAuth} from "../../../utils/auth";

const Form  = ({uuid, ready, currentForm, steps, currentStep, handleSubmit, initialValues}) => {
    const {getPatientPortalUrlPrefix} = useAuth();
    const formState = useSelector(state => state.form.wizard);
    const formStateValues = formState && formState.values ? formState.values : [];

    const currentDocument = useSelector(state => state.main.currentDocument);
    const dispatch = useDispatch();
    const [subTitle, setSubTitle] = useState('');
    const {documentPage} = useParams();

    useEffect(() => {
        enableJCF();
        updateTitle();
    }, [ready, currentStep]);
    const enableJCF = () => {
        jcf.setOptions('Select', {
            wrapNative: false,
            wrapNativeOnMobile: false,
            fakeDropInBody: true,
        });
        jcf.replaceAll();
    };

    const isSidebarInMobileMode = useMediaQuery({ maxDeviceWidth: 768 });

    const medicationInputEmpty = (inputGroupName) => {
        console.log('[medicationInputEmpty]', inputGroupName);

        return (Object.keys(formStateValues).indexOf(inputGroupName) === -1)
            || (typeof formStateValues[inputGroupName] !== 'object')
            || (formStateValues[inputGroupName].constructor !== Array)
            || (formStateValues[inputGroupName].length < 1)
            || (formStateValues[inputGroupName][0] === '')
            || (formStateValues[inputGroupName][0] === null)
            || (formStateValues[inputGroupName][0] === undefined)
        ;
    }

    const handleClickNext = () => {
        // ACA-276 - wrong values in formState
        console.log('[Form] handleClickNext formState', formState, formStateValues);
        let requiredFields = parseRequiredFields(currentForm, formState);
        let valid = formStateValues !== undefined;

        requiredFields.forEach((item) => {
            if (item === '__medications') {
                // medications list use array datatype, so this patch is used for it
                if((formStateValues === undefined)
                    || medicationInputEmpty('medication')
                    || medicationInputEmpty('medicationDose')
                    || medicationInputEmpty('medicationFrequency')
                ) {
                    console.log('[__medications] Required field is not set', item);
                    PubSub.publish(pubSubMessages.VALIDATION_ERROR_SET, item);
                    if (valid) {
                        PubSub.publish(pubSubMessages.VALIDATION_ERROR_SCROLL, item);
                    }
                    valid = false;
                }
            } else if((formStateValues === undefined) || (Object.keys(formStateValues).indexOf(item) === -1) || (formStateValues[item] === '') || (formStateValues[item] === null)
                || (typeof formStateValues[item] === 'object') && (_.values(formStateValues[item]).every(_i => _i === false))
                || ((formStateValues[item].value !== undefined) && (formStateValues[item].value === ''))
            ) {
                console.log('[Form] Required field is not set', item);
                PubSub.publish(pubSubMessages.VALIDATION_ERROR_SET, item);
                if (valid) {
                    PubSub.publish(pubSubMessages.VALIDATION_ERROR_SCROLL, item);
                }
                valid = false;
            }
        });

       console.log('[Form] base validation', valid);

        valid = customValidationForm(currentForm, formState, valid, dispatch);
//        console.log('[Form] custom validation', valid);

        valid = reviewBackendValidation_onSubmit(currentStep, currentDocument, formStateValues, valid, dispatch);
        console.log('[Form] reviewBackendValidation', valid);

        if (valid) {
            let nextStepUri = '';
            if (currentStep < steps.length) {
                nextStepUri = `/${getPatientPortalUrlPrefix()}/document/2/${currentDocument.hash}/${currentStep+1}`;
            } else {
                nextStepUri = `/${getPatientPortalUrlPrefix()}/review/${currentDocument.hash}`;
            }
            dispatch(wizardActionCreators.wizardSave(nextStepUri));
        }
    };

    const renderNextButton = () => {
        return (
            <li>
                <a onClick={handleClickNext} className="btn btn-primary btn-lg">Next: {(currentStep < steps.length) ? steps[currentStep] : 'Review'}</a>
            </li>
        );
    };

    const renderCard = (card, index) => {
        const labelCols = currentForm.default.labelCols;
        const itemCols = currentForm.default.itemCols;
        const cardJsx = <Card card={card} key={index} index={index} labelCols={labelCols} itemCols={itemCols} formInitialValues={initialValues}/>;

        if (card.visibleIf) {
            return <VisibleIf {...card.visibleIf} key={index} >{cardJsx}</VisibleIf>
        } else {
            return cardJsx;
        }
    };
    //
    const isElementTopLessThat = (eltId, limit) => {
        var elt = document.getElementById(eltId);
        if (!elt) {
            return false;
        }
        var rect = elt.getBoundingClientRect();
        return rect.top < limit;
    };

    const updateTitle = () => {
        if (currentForm && currentForm.cards) {
            // console.log('updateTitle !');
            let founded = '';
            currentForm.cards.forEach((card, index) => {
                if (!founded || isElementTopLessThat('card-' + index, 70)) {
                    founded = card.title;
                }
            });

            // console.log('Visible card - ',founded);
            if (founded) {
                setSubTitle(founded);
            }
        }
    };

    const throttledUpdateTitle = throttle(updateTitle, 200);

    useEffect(() => {
        window.addEventListener('scroll', throttledUpdateTitle);
        updateTitle();
        return () => {
            window.removeEventListener('scroll', throttledUpdateTitle)
        }
    }, [ready, currentStep, uuid]);

    const renderForm = () => {
        if (ready && (parseInt(documentPage) === currentStep)) {
            return (
                <>
                    <StickyContainer>
                        <SubHeader step={currentStep} title={currentForm.title} onNextStep={handleClickNext} subTitle={subTitle}/>
                        <form autoComplete="off" action="#" className="row step-form" onSubmit={handleSubmit}>
                            {!isSidebarInMobileMode && <ProgressPanelDesktop onNextStep={handleClickNext}/>}
                            <div className="col-12 col-md-8 col-lg-9 content">
                                {currentForm.devCard && <DevCard />}
                                {currentForm.cards.map(renderCard)}

                                <ul className="btn-navigation pt-2 m-0 list-unstyled d-flex flex-column flex-nowrap flex-sm-row-reverse align-items-sm-center">
                                    {renderNextButton()}
                                    <BackButton/>
                                </ul>
                            </div>

                        </form>
                    </StickyContainer>
                </>
            );
        } else {
            return (<div />);
        }
    };

    return (

        <main id="main" className="pt-0">
            <section className="setps-block container">
                <div>
                    {renderForm()}
                </div>
            </section>
        </main>

    );
};

const mapStateToProps = state => ({
    ...state.wizard
});

export default connect(mapStateToProps)(
                    withRouter(
                        reduxForm ({
                            form: 'wizard',
                            destroyOnUnmount: true, // false??
                            forceUnregisterOnUnmount: true,
                            enableReinitialize: true,
                        }) (Form)
                    )
                );