import React from "react";

const Error = ({children}) => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-6">
                    <div className="alert alert-danger">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;