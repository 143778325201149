import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: true,
    error: false,
    impersonated: false,
    impersonateToken: null,
    manager: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IMPERSONATED_ADMISSION:
            return {
                ...state,
                impersonated: true,
                error: false,
                loading: false,
                impersonateToken: action.token,
                manager: action.manager,
            };
        case actionTypes.SET_IMPERSONATED_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
                impersonated: false,
                impersonateToken: null,
                manager: {}
            };
    }
    return state;
}

export default reducer;