import React, {useRef} from 'react';
import SignatureCanvas from "react-signature-canvas";

const Signature = props => {
    const sigPadRef = useRef(null);

    const {input, label, placeholder, meta, height = 200, className='sigCanvas', name = '_signature', required = true} = props;
    const error = false;

    const clearSignature = () => {
        sigPadRef.current.clear();
        input.onChange(null);
    }
    const handleSignatureEnd = () => {
        let canvas = sigPadRef.current.getTrimmedCanvas();
        console.log('[Signature]', canvas.width, canvas.height);
        if ((canvas.width > 50) && (canvas.height > 50)) {
            input.onChange(sigPadRef.current.getTrimmedCanvas().toDataURL('image/png'));
        }
    };

    let canvasWidth = 0;
    if (window.innerWidth < 600) {
        canvasWidth = window.innerWidth-50;
    } else {
        canvasWidth = 500;
    }

    return (
        <div className={`row no-gutters ${meta.touched && meta.error && "form-error"}`}>
            <div className="col-12 col-lg-4">
                <label htmlFor="Signature" className="label m-lg-0">Patient or Guardian’s
                    Signature</label>
                <div className="btn-holder reset-signature-btn">
                    <button className="btn btn-secondary" type="button" onClick={clearSignature}>Clear signature</button>
                </div>
            </div>
            <div className="col-12 col-lg-8 signature">
                <SignatureCanvas ref={sigPadRef} penColor='black' canvasProps={{width: canvasWidth, height, className, style: {flex: "0 0 100%"}}} onEnd={handleSignatureEnd}/>
                <div className="signature-notice-container">
                    <div className="signature-notice">
                        SIGN HERE USING YOUR MOUSE OR FINGER
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signature;