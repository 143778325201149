import { combineReducers } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { reducer as formReducer } from 'redux-form'

import customValidationErrorsReducer from "./reducers/customValidationErrors";
import impersonateReducer from "./reducers/impersonate";
import mainReducer from "./reducers/main";
import themeReducer from "./reducers/theme";
import progressPanelReducer from "./reducers/progressPanel";
import reviewReducer from "./reducers/review";
import wizardReducer from "../components/Document_v2/store/reducers/wizard";


const createRootReducer = (history) => combineReducers({
    customValidationErrors: customValidationErrorsReducer,
    router: createRouterReducer(history),
    form: formReducer,
    impersonate: impersonateReducer,
    main: mainReducer,
    progressPanel: progressPanelReducer,
    review: reviewReducer,
    theme: themeReducer,
    wizard: wizardReducer,
});

export default createRootReducer;