import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useAuth} from "../../../utils/auth";

const ProgressPanelItem = ({index, item, onNextStep}) => {
    const dispatch = useDispatch();
    const {getPatientPortalUrlPrefix} = useAuth();

    const {currentStep, documentHash, allStepsEnabled} = useSelector(state => state.progressPanel);

    const itemHref = `/${getPatientPortalUrlPrefix()}/document/2/${documentHash}/${index+1}`;

    const styles = [];

    // if (allStepsEnabled && ((index+1) === steps.length)) {
    //     // when allStepsEnabled review step have separate styles, we need updated padding/margin for last item.
    //     styles.push("progress-bar-last");
    // }

    let itemBlock = item;

    if (currentStep === -1) {
        // one of previous steps on review page
        itemBlock = <Link to={itemHref}>{item}</Link>;
    } else if ((index + 1) === currentStep) {
        styles.push("active");
    } else if ((index + 1) < currentStep) {
        // one of previous steps
        itemBlock = <Link to={itemHref}>{item}</Link>;
    } else if ((index) === currentStep) {
        // next step
        itemBlock = <a onClick={onNextStep} className="cursor-pointer">{item}</a>;
    } else if(allStepsEnabled) {
        // one of the next steps
        itemBlock = <Link to={itemHref}>{item}</Link>;
    } else {
        // one of the next steps
        styles.push("progress-bar-inactive");
    }

    return (
        <li className={styles.join(' ')}>{itemBlock}</li>
    );
};

export default ProgressPanelItem;