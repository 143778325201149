import React, {useState} from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {useSelector} from "react-redux";
import { change } from 'redux-form';
import {useDispatch} from 'react-redux';

export const ReduxGooglePlacesAddress = ({input, placeholder, targetSuburb, targetState, targetPostcode, requiredNumber=false, required = false, targetCountry = null, restrictCountry = 'au'}) => {
    const dispatch = useDispatch();
    const [errorNoStreetNumber, setErrorNoStreetNumber] = useState(false);

    const wizardForm = useSelector(state => state.form.wizard);
    console.log('[ReduxGooglePlacesAddress] render()');

    const handleChange = value => {
        setErrorNoStreetNumber(false);
        input.onChange(value);
    }

    if (targetCountry && restrictCountry) {
        console.log('[ReduxGooglePlacesAddress] restrictCountry disabled, because targetCountry is set');
    }

    const searchOptions = {
        componentRestrictions: {country: targetCountry ? null : restrictCountry},
        types: ['address']
    };

    async function getDetailsForPlaceId(userInputAddress, placeId) {
        //tslint:disable-next-line:no-any
        return new Promise((resolve, reject) => {
            // @ts-ignore
            const placesService = new window['google'].maps.places.PlacesService(document.createElement('div'));
            const request = {
                placeId,
                fields: ['address_components']
            };
            placesService.getDetails(request, (result, status) => {
                let address = '';
                let addSubUrb = '';
                let addState = '';
                let addPostCode = '';
                console.log('[ReduxGooglePlacesAddress] DONE', result);
                if (wizardForm && wizardForm.values) {
                    result.address_components.map(_item => {
                        _item.types.map(_type => {
                            switch (_type) {
                                case "country":
                                    if (targetCountry) {
                                        dispatch(change('wizard', targetCountry, _item.long_name));
                                    }
                                    break;
                                case "locality":
                                    console.log('[ReduxGooglePlacesAddress] SET', targetSuburb, _item.short_name, wizardForm.values[targetSuburb]);
                                    if (targetSuburb) {
                                        dispatch(change('wizard', targetSuburb, _item.short_name));
                                    } else {
                                        addSubUrb = _item.short_name;
                                    }
                                    break;
                                case "administrative_area_level_1":
                                    console.log('[ReduxGooglePlacesAddress] SET', targetState, _item.short_name);
                                    if (targetState) {
                                        dispatch(change('wizard', targetState, _item.short_name));
                                    } else {
                                        addState = _item.short_name;
                                    }
                                    break;
                                case "postal_code":
                                    console.log('[ReduxGooglePlacesAddress] SET', targetPostcode, _item.short_name);
                                    if (targetPostcode) {
                                        dispatch(change('wizard', targetPostcode, _item.short_name));
                                    } else {
                                        addPostCode = _item.short_name;
                                    }
                                    break;
                                case "subpremise":
                                    // unit number is not passed, so parse userInputAddress instead
                                    address += _item.short_name + '/';
                                    break;
                                case "street_number":
                                    // unit number is not passed, so parse userInputAddress instead
                                    address += _item.short_name + ' ';
                                    break;
                                case "route":
                                    address += _item.long_name;
                                    break;
                            }
                        })
                    })

                    var regex = RegExp('^(.*)'+address), // get all the user entered values before a match with the first word from the Google result
                        result = regex.exec(userInputAddress);

                    // if ( Array.isArray(result) ) {
                    //     address = result[1] + address;
                    //     if (addSubUrb) {
                    //         address = address  + ', ' + addSubUrb + ' ' + addState;
                    //     }
                    //     if (addPostCode) {
                    //         address = address  + ', ' + addPostCode;
                    //     }
                    //     console.log('[ReduxGooglePlacesAddress] parsed full address ', input.name, address);
                    // } else {
                    //     console.log('[ReduxGooglePlacesAddress] cant parse full address ', input.name, userInputAddress, address, result);
                    // }

                    var hasNumber = /\d/;
                    if (!requiredNumber || hasNumber.test(address)) {
                        setErrorNoStreetNumber(false);
                        dispatch(change('wizard', input.name, address));
                    } else {
                        setErrorNoStreetNumber(true);
                        dispatch(change('wizard', input.name, ''));
                    }
                } else {
                    console.error('[ReduxGooglePlacesAddress] wizardForm.values are not accessible');
                }
            });
        });
    }

    const handleSelect = async (address, placeId) => {
        handleChange(address);
        console.log('[ReduxGooglePlacesAddress] handleSelect', address, placeId);
        const details = await getDetailsForPlaceId(address, placeId);
    };

    return (
        <PlacesAutocomplete
            value={input.value}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <>
                    {errorNoStreetNumber && <span className="error-span">Please enter street number</span>}
                    <input
                        {...getInputProps({
                            placeholder: placeholder,
                            className: 'form-control form-control-sm',
                        })}
                        autoComplete={Math.random().toString(36).substring(2, 15)}
                    />
                    <div className="google-places-field">
                        {((suggestions.length > 0) || loading) &&
                        <div className="jcf-select-drop-content google-places-dropdown">
                            <span className="jcf-list">
                            <span className="jcf-list-content">
                            <ul>
                                {loading && <li><span className="jcf-option" data-index="0">Loading...</span></li>}
                                {suggestions.map((suggestion, index) => {
                                    return (
                                        <li key={index} {...getSuggestionItemProps(suggestion)}><span
                                            className='google-places-option'>{suggestion.description}</span></li>
                                    );
                                })}
                            </ul>
                            </span>
                            </span>
                        </div>
                        }
                    </div>
                </>
            )}
        </PlacesAutocomplete>

    )
};

export default ReduxGooglePlacesAddress;