import React, {createRef, useState} from 'react';
import {reduxForm } from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import { push } from '@lagunovsky/redux-react-router';
import {StickyContainer} from "react-sticky";

import SubHeader from "../Document_v2/Form/SubHeader";
import Signature from "./Signature";
import ReviewValues from "./ReviewValues";
import FileUploader from "./FileUploader";
import * as actionTypes from '../../store/actions/actionTypes';
import * as reviewActions from '../../store/actions/review';
import ProgressPanelDesktop from "../Document_v2/ProgressPanel/ProgressPanelDesktop";

const Review = ({handleSubmit}) => {
    const [subTitle, setSubTitle] = useState('');
    const dispatch = useDispatch();
    const documentProcessing = useSelector(state => state.main.documentProcessing);
    const {admissionType, sigPadFilled, admissionDocumentType} = useSelector(state => state.review);
    const reduxForm = useSelector(state => state.form);

    const formSubmitHandler = values => {
        let valid = true;
        if (!sigPadFilled) {
            valid = false;
            dispatch({type: actionTypes.REVIEW_ADD_ERROR, error: '_signature'});
        }
        if (!reduxForm || !reduxForm.review || !reduxForm.review.values || !reduxForm.review.values._signatureName) {
            valid = false;
            dispatch({type: actionTypes.REVIEW_ADD_ERROR, error: '_signatureName'});
        }
        if (valid) {
            doSubmit(values);
        }
    }

    const doSubmit = async (values) => {
        values._signature = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
        dispatch(reviewActions.sign(values));
    };

    let sigPadRef = createRef();

    return (
        <main id="main" className="pt-0">
            <section className="setps-block container">
                <StickyContainer>
                    <SubHeader subTitle={subTitle} step={admissionType.steps.length + 1} title="Review" customSave={() => dispatch(push('/'))}/>
                    <form autoComplete="off" className="row step-form" onSubmit={handleSubmit(formSubmitHandler)}>
                        <div className="col-12 col-md-8 col-lg-9 content">
                            <ReviewValues setSubTitle={setSubTitle}/>
                            {admissionDocumentType.fileUploading_enable && <FileUploader />}
                            <Signature ref={sigPadRef}/>
                            <div className="btn-holder">
                                <button className="btn btn-primary btn-lg" type="submit" disabled={documentProcessing}>Submit</button>
                            </div>
                        </div>
                        <ProgressPanelDesktop/>
                    </form>
                </StickyContainer>
            </section>
        </main>
    );
};

export default reduxForm ({
    form: 'review',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
}) (Review);