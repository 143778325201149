import React from 'react';
import {Field} from 'redux-form';
import DatePickerFieldComponent from "./ReduxComponent/DatePicker";

const DatePicker = (props) => {
    return (
        <div className={props.itemCols}>
            <Field component={DatePickerFieldComponent} {...props} />
        </div>
    );
};

export default DatePicker;