import {visibleIfArray} from "./visibleIf";
import {form_valueExists, hcfLabel} from "./lib";

const pushIf = (itemName, itemLabel, values, reviewFields) => {
    if (form_valueExists(values, itemName)) {
        reviewFields.push(
            {
                name: itemName,
                label: itemLabel,
                value: (typeof values[itemName] === "object")?values[itemName]['value'] : values[itemName]
            }
        );
    }
};

export const getReviewValues = (admissionType, values) => {
    const reviewCards = [];
    admissionType.steps.forEach(step => {
        step.form.cards.forEach(card => {
            if (card.skipReview) {
                return;
            }

            if (!card.visibleIf || visibleIfArray(card.visibleIf, values)) {
                let reviewFields = [];
                card.rows.forEach(row => {
                    row.items.forEach(item => {
                            if (!row.visibleIf || visibleIfArray(row.visibleIf, values)) {
                                let label = item.label;
                                if (item.labelHtml) {
                                    label = item.labelHtml;
                                } else if (item.reviewLabel) {
                                    label = item.reviewLabel;
                                }

                                switch (item.type) {
                                    // TODO: add MedicationList
                                    case "CustomList":
                                        const rowsCount = () => values[`${item.namePrefix}_count`] || 3;
                                        for (let i = 0; i < rowsCount(); i++) {
                                            item.columns.map((column, columnIndex) => {
                                                pushIf(`${item.namePrefix}${column.namePrefix}${i}`, `${column.label} ${i+1}`, values, reviewFields);
                                            });
                                        }
                                        break;
                                    case "Checkboxes":
                                        if ((Object.keys(values).indexOf(item.name) !== -1) && (values[item.name] !== '')) {
                                            let trueValues = [];
                                            Object.keys(values[item.name]).forEach(key => {
                                                if (values[item.name][key]) {
                                                    trueValues.push(key);
                                                }
                                            });
                                            reviewFields.push(
                                                {
                                                    name: item.name,
                                                    label: label,
                                                    value: trueValues.join(', ')
                                                }
                                            );
                                        }
                                        break;
                                    case "BodyMassIndex":
                                        pushIf('bodyMass_height', 'Your height', values, reviewFields);
                                        pushIf('bodyMass_weight', 'Your weight', values, reviewFields);
                                        break;
                                    case "DVACardColour":
                                        pushIf('DVACardColour', 'DVA Card Colour', values, reviewFields);
                                        pushIf('DVACard_expiry', 'DVA Card Expiry', values, reviewFields);
                                        break;
                                    case "SuburbStatePostcode":
                                        const reviewPrefix = item.reviewPrefix ? item.reviewPrefix : '';
                                        pushIf(`${item.namePrefix}suburb`, `${reviewPrefix}Suburb`, values, reviewFields);
                                        pushIf(`${item.namePrefix}state`, `${reviewPrefix}State`, values, reviewFields);
                                        pushIf(`${item.namePrefix}postcode`, `${reviewPrefix}Postcode`, values, reviewFields);
                                        break;
                                    case "MedicationDetails":
                                        pushIf(`${item.namePrefix}name`, 'Name of Medication', values, reviewFields);
                                        pushIf(`${item.namePrefix}lastDate`, 'Date last taken', values, reviewFields);
                                        pushIf(`${item.namePrefix}stillTaking`, 'Still taking', values, reviewFields);
                                        break;
                                    case "DateInput":
                                    case "DoubleInput":
                                        pushIf(item.name1, item.label1, values, reviewFields);
                                        pushIf(item.name2, item.label2, values, reviewFields);
                                        break;
                                    case "Radio":
                                    case "Date":
                                    case "Date2":
                                    case "Date3":
                                    case "DateMMYY":
                                    case "Date4":
                                    case "YesNo":
                                    case "Input":
                                    case "Select":
                                    case "Textarea":
                                    case "SelectAutocomplete":
                                        if (item.hcfOptions) {
                                            reviewFields.push(
                                                {
                                                    name: item.name,
                                                    label: label,
                                                    value: form_valueExists(values, item.name) ? hcfLabel(item.options, values[item.name]) : ''
                                                }
                                            );
                                        } else {
                                            pushIf(item.name, label, values, reviewFields);
                                        }
                                        break;
                                    case "GooglePlacesAddress":
                                        if (item.name) {
                                            pushIf(item.name, label, values, reviewFields);
                                        } else {
                                            pushIf(`${item.namePrefix}address`, label, values, reviewFields);
                                        }
                                        break;
                                    // TODO: ACA-222 - Usage reference. Remove this TODO label.
                                    case "SelectAsync":
                                        pushIf(`${item.name}_label`, label, values, reviewFields);
                                        break;
                                }
                            }
                        }
                    );
                });
                if (reviewFields.length) {
                    reviewCards.push({
                        cardTitle: card.reviewTitle ? card.reviewTitle : card.title,
                        reviewFields: reviewFields
                    });
                }
            }
        });
    });

    // console.log('getReviewValues', reviewCards);
    return reviewCards;
};

