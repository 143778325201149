import React, {useEffect} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import * as ComplexPropTypes from '../../complexPropTypes';
import ReduxSelectAutocomplete from "./ReduxComponent/ReduxSelectAutocomplete";
import {useSelector} from "react-redux";

const SelectAutocomplete = ({name, itemCols, options, setError}) => {
    const error = useSelector(state => state.customValidationErrors.errors[name]);
    useEffect(() => {
        setError(!!error);
    }, [error]);
    return (
        <div className={itemCols}>
            {error && <div className="input-error2">{error}</div>}
            <Field className="sm" name={name} component={ReduxSelectAutocomplete} options={options} />
        </div>
    );
};

export default SelectAutocomplete;