import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useAuth} from "../utils/auth";

const Message = ({message}) => {
    const {getPatientPortalUrlPrefix} = useAuth();
    return (
        <main id="main" className="pt-4 pt-md-5 pb-0 pb-md-6">
            <div className="container-simple">
                <section className="tasks-block payment-completed">
                    <div className="head-block head-simple">
                        <h2>{message}</h2>
                    </div>

                    <Link to={`/${getPatientPortalUrlPrefix()}/tasks`} className="btn btn-primary btn-lg btn-square">Return home</Link>
                </section>
            </div>
        </main>
    )
}

export default Message;