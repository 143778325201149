import React from 'react';
import Select, { components } from 'react-select'
import {isArray} from 'underscore';

import {Theme_ReactSelectAutocomplete} from "../../../../Theme/Theme_ReactSelect";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="autocomplete-dropdown" />
        </components.DropdownIndicator>
    );
};

export const ReduxSelectAutocomplete = props => {
    const { input, options } = props;

    const getValueOption = () => {
        if (typeof input.value === 'object') {
            return input.value;
        } else {
            const selection = patchedOptions().filter(_i => _i.value === input.value);
            return selection.length ? selection[0] : null;
        }
    }
    const patchedOptions = () => {
        if (!isArray(options)) {
            return [];
        }
        return options.map(item => {
            return {
                ...item,
                value: item.value.toString(),
            }
        })
    }

    return (
        <Select
            {...input}
            value={getValueOption()}
            styles={Theme_ReactSelectAutocomplete()}
            onChange={value => input.onChange(value)}
            onBlur={event => event.preventDefault()}
            options={patchedOptions()}
            className="select-autocomplete"
            components={{DropdownIndicator}}
        />
    )
}

export default ReduxSelectAutocomplete;