import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";

const AgreeBtn = ({agree_title}) => {
    const navigate = useNavigate();
    const documentProcessing = useSelector(state => state.main.documentProcessing);

    return (
        <div className="row no-gutters pt-3 pt-lg-4 pb-3">
            <div className="col-12">
                <ul
                    className="btn-navigation m-0 list-unstyled w-100 d-flex flex-column flex-nowrap flex-sm-row-reverse align-items-sm-center">
                    <li>
                        <button type="submit" className="btn btn-primary btn-lg btn-square" disabled={documentProcessing}>
                            {agree_title}
                        </button>
                    </li>
                    {/*<li>*/}
                    {/*    <a onClick={() => history.goBack()} className="back-link"><i className="icon icon-arrow-left" /> <span*/}
                    {/*        className="ml-2">Go back</span></a>*/}
                    {/*</li>*/}
                </ul>
            </div>
        </div>
    );
}

export default AgreeBtn;