import React, {Fragment} from "react";
import {useSelector} from "react-redux";

const Theme_Footer = () => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    if (loadedThemeId !== false) {
        return <footer className="footer footer-small" dangerouslySetInnerHTML={{__html: theme.patientPortal_footerHtml}}/>
    }
    return null;
}
export default Theme_Footer;