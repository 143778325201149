import React from "react";
import {useSelector} from "react-redux";

const Theme_WelcomeLogo = ({onClick}) => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    const logoUri = theme.file_welcomeLogo ? theme.file_welcomeLogo : theme.file_logo;

    if (loadedThemeId) {
        return (
            <div className={`${theme.class_logo} cursor-pointer`} onClick={onClick}>
                <img src={`${theme.cdn_path}${logoUri}`} alt={theme.patientPortal_logoAlt}/>
            </div>
        );
    }
    return null;
}

export default Theme_WelcomeLogo;