import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    ${props => {
    let colorUI = '';
    let baseText = '';
    let colorUIGrey = '';
    let colorUIBtnLabel = '';
    if (props.loadedThemeId && props.color_enable) {
        if (props.color_UI) {
            colorUI = `.jcf-select-drop .jcf-hover, .jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover {
                        background-color: ${props.color_UI} !important;
                    }
                    .pre-admission .icon-box {
                        color: ${props.color_UI} !important;
                    }
                    .jcf-focus, .jcf-focus *, .jcf-select-drop .jcf-select-drop-content, .form-control:focus {
                        border-color: ${props.color_UI} !important;
                    }
                    .check-btns .custom-control-input:checked ~ .custom-control-label {
                        border-color: ${props.color_UI} !important;
                    }
                    .counter.active {
                        color: ${props.color_UI} !important;
                    }
                    .btn-primary:hover, .btn-primary {
                        background-color: ${props.color_UI} !important;
                    }
                    .head-block .back-link:hover i, .tasks-item:hover .icon, .setps-block .number, .setps-block .step-opener, a, .footer .copyright a:hover {
                        color: ${props.color_UI};
                    }
                    .btn-primary, .step-widget .step-list li.active::before {
                        border-color: ${props.color_UI} !important;
                    }
                    .step-widget .step-list li::before {
                        border: 2px solid ${props.color_UI};
                        background: ${props.color_UI};
                    }
                    .step-widget .step-list li::after {
                        background: ${props.color_UI};
                    }
                    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
                        border: 1px solid ${props.color_UI};
                        background: ${props.color_UI};
                    }
                    .google-places-dropdown {
                        border: 1px solid ${props.color_UI}; 
                    }
                    `
        }

        if (props.color_baseText) {
            baseText = ` .jcf-list .jcf-option{
                        color: ${props.color_baseText} !important;
                    }
                    .step-form .label, .review-form .label, .step-form label, .review-form label {
                        color: ${props.color_baseText} !important;
                    }
                    .text-dark {
                        color: ${props.color_baseText} !important;
                    }
                    .ui-widget-content, .ui-widget-header {
                        color: ${props.color_baseText} !important;
                    }
                    .setps-block .title-holder, .h3 {
                        color: ${props.color_baseText} !important;
                    }
                    .setps-block .title-holder.active-title::before {
                        background: ${props.color_baseText} !important;
                    }
                    .step-widget .step-list {
                        color: ${props.color_baseText} !important;
                    }
                    li, td, strong {
                        color: ${props.color_baseText} !important;
                    }
                    `;
        }
        if (props.color_UI_grey) {
            colorUIGrey = ` .jcf-select {
                            border-color: ${props.color_UI_grey} !important;
                        }`;
        }
        if (props.color_UI_buttonLabel) {
            colorUIBtnLabel = `
            .jcf-select-drop .jcf-hover {
                color: ${props.color_UI_buttonLabel} !important;
            }`
        }
    }
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // TODO: REFACTOR - custom Lifehouse changes
    /* 
    .setps-block .title-holder, .step-widget .step-list li.active {
                color: #000;
            }
            .setps-block .title-holder.active-title::before {
                background: #000;
            }
     */
    console.log('customCss', `
            ${props.customCss}
            ${baseText}
            ${colorUIGrey}
            ${colorUI}
            ${colorUIBtnLabel}
            `);
    return `
            ${baseText}
            ${colorUIGrey}
            ${colorUI}
            ${colorUIBtnLabel}
            ${props.customCss}
            `;
}}`

export const StyledDiv = styled.div`
            ${props => {
    let baseText = null;
    let baseLightText = null;
    let greyText = null;
    let colorUI = null;
    let colorUIGrey = null;
    let colorUIBtnLabel = null;
    if (props.loadedThemeId && props.color_enable) {
        if (props.color_baseText) {
            baseText = `& .skew-section .signin-text h1, & .skew-section .signin-text, & h2, & h3, & label, & .form-control,
                            & a.tasks-item, & span.text-dark, & div.text p, & div.text-row, & span.label, & button.btn.btn.btn.btn-secondary, & div.signature-notice, 
                            & span.steps, & .jcf-list .jcf-option, .jcf-select .jcf-select-text, & .counter.blue {
                            color: ${props.color_baseText} !important;
                            }`;
        }
        if (props.color_greyText) {
            greyText = `& span.tasks-text, & span.copyright, & span.copyright a, & span.help-text {
                            color: ${props.color_greyText} !important;
                        }`;
        }
        if (props.color_UI_grey) {
            colorUIGrey = `& .form-control::placeholder, & i.icon-arrow-left, & span.icon-arrow-right {
                            color: ${props.color_UI_grey} !important;
                        }
                        & .btn.btn-secondary:hover {
                            background: #eef2f6 !important;
                        }
                        & .form-control, & .custom-control-input:checked ~ .custom-control-label::before, & .custom-control-label::before {
                            border-color: ${props.color_UI_grey} !important;
                        }`;
        }
        if (props.color_UI) {
            colorUI = `& button.btn-primary {
                            background-color: ${props.color_UI} !important;
                        }
                        & button.btn-primary {
                            border-color: ${props.color_UI} !important;
                        }
                        & .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='${props.color_UI.replace('#', '%23')}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        }
                        .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='${props.color_UI.replace('#', '%23')}'/%3e%3c/svg%3e");
                        }
`
        }
        if (props.color_lightText) {
            baseLightText = ` & .counter.blue {
                    background-color: ${props.color_lightText} !important;
                }
                & .signature canvas {
                    border-color: ${props.color_lightText};
                }
            `
            
        }
        if (props.color_UI_buttonLabel) {
            colorUIBtnLabel = `
            & .btn:not([class^="btn-outline-"]):not([class*="btn-outline-"]):not(.btn-link) {
                color: ${props.color_UI_buttonLabel} !important;
            }
            `
        }
    }
    return `& {
                height: 100%;
            };
            ${baseText}
            ${greyText}
            ${colorUI}
            ${colorUIGrey}
            ${colorUIBtnLabel}
            ${baseLightText}
        `;
}
}
        `