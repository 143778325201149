import * as actionTypes from './actionTypes';
import axios from 'axios';
import { push } from '@lagunovsky/redux-react-router'
import {useAuthThunk} from "../../utils/auth";

export const processDocument = () => {
    return (dispatch, getState) => {
        const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuthThunk(getState);
        const document = getState().main.currentDocument;
        const form = getState().form;
        console.log('[processDocument]', document);
        dispatch({type: actionTypes.DOCUMENT_PROCESSING_START});

        axios.post(
            `/api/patient/admission/document/${document.hash}/process`,
            (form && form.document && form.document.values) ? form.document.values : null,
            getAxiosAuthConfig())
            .then((response) => {
                // dispatch({type: actionTypes.PROGRESS_BAR_STOP});
                // dispatch({type: actionTypes.ADD_NEW_ADMISSION_START, value: response.data});
                // dispatch(push(`/admission/add/p/${selectedHospital}/${selectedType}/${selectedMeElse}/1`));
                console.log('[processDocument] response', response);
                dispatch(push(`/${getPatientPortalUrlPrefix()}/tasks`));
                dispatch({type: actionTypes.DOCUMENT_PROCESSING_DONE});
            });
    }
};