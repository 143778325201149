import React from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';

import SqreenLogo from '../assets/images/logo-sqreen.png';
import ProgressPanelItem from "./ProgressPanelItem";
import ProgressPanelReviewItem from "./ProgressPanelReviewItem";
// import contactLink from "../../skin/contactLink";

const ProgressPanelMobile = ({onNextStep}) => {
    const steps = useSelector(state => state.progressPanel.steps);

    return (
            <section className="widget step-widget">
                <ul className="step-list">
                    {steps.map((item, index) => <ProgressPanelItem onNextStep={onNextStep} item={item} index={index} key={index}/>)}
                    <ProgressPanelReviewItem onNextStep={onNextStep} />
                </ul>
                {/*<div className="btn-holder d-flex flex-row flex-wrap py-1 mx-n2">*/}
                {/*    <a href={contactUrl} className="btn btn-secondary flex-grow-1 m-1 px-2"  target="_blank">*/}
                {/*        <i className="icon icon-chat"/> Contact Us*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div className="logo-wrap text-center border-top pt-3 mt-3 pt-lg-4 mt-lg-4">*/}
                {/*    <a href="#" className="logo d-inline-block">*/}
                {/*        <img src={SqreenLogo} width="109" height="36" alt="Protected by Sqreen"/>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </section>
    );
};

export default ProgressPanelMobile;