import {requiredFieldsForItem} from "./parseRequiredFields";
import * as dotProp from "dot-prop-immutable";

// patch should be added to backend/src/Helper/YamlFormHelperV2.php
const visibleIfPatch = (name, formValues) => {
    switch (name.toLowerCase()) {
        case "#imp-70":
            // #IMP-70 - hide medicare, if selfFunded = YES and ("overseas patient" is selected) and ("self funded" IS NOT selected)
            const isSelfFundedYes = (dotProp.get(formValues, 'selfFunded') === 'yes');
            const isOverseasPatientSelected = (dotProp.get(formValues, 'selfFunded_type', {})["overseas patient"]);
            const isSelfFundedSelected = (dotProp.get(formValues, 'selfFunded_type', {})["self funded"]);

            // console.log('#IMP-70', isSelfFundedYes, isOverseasPatientSelected, isSelfFundedSelected);
            const hideMedicare = isSelfFundedYes && isOverseasPatientSelected && !isSelfFundedSelected;
            return !hideMedicare;
        default:
            console.error('Unknown visibleIf patch - ', name);
            return;
    }
}

const visibleIf = (name, value, reduxForm) => {
    // either form OR form values
    const values = reduxForm ? (reduxForm.values ? reduxForm.values : reduxForm) : [];
    if (name && name.substr(0,1) === '#') {
        return visibleIfPatch(name, values);
    }
    if (values && values[name]) {
        if ((typeof value === 'string') || (value instanceof String)) {
            if ((typeof values[name] === 'string') || (values[name] instanceof String)) {
                if (values[name] === value) {
                    // console.log('string = YES');
                    return true;
                }
            }
            if ((typeof values[name] === 'object')) {
                if (values[name][value] === true) {
                    // console.log('string = YES');
                    return true;
                }
            }
        }
        if ((typeof value === 'object') && (value.constructor === Array)) {
            if ((typeof values[name] === 'object')) {
                if ((values[name].constructor === Array)) {
                    let haveInvalidFields = false;
                    let haveValidFields = false;
                    values[name].forEach(_item => {
                        if (value.includes(_item)) {
                            haveValidFields = true;
                        } else {
                            haveInvalidFields = true;
                        }
                    })
                    if (haveValidFields && !haveInvalidFields) {
                        return true;
                    }
                } else {
                    // key => true | false format
                    let haveInvalidFields = false;
                    let haveValidFields = false;
                    for (const [_key, _item] of Object.entries(values[name])) {
                        if (_item) {
                            if (value.includes(_key)) {
                                haveValidFields = true;
                            } else {
                                haveInvalidFields = true;
                            }
                        }
                    }

                    if (haveValidFields && !haveInvalidFields) {
                        return true;
                    }

                }
            } else {
                if (value.includes(values[name])) {
                    // console.log('array = YES');
                    return true;
                }
            }
        }
    }
    return false;
}

export const visibleIfArray = (visibleIfTag, values) => {
    if ((typeof visibleIfTag === 'object') && (visibleIfTag.constructor === Array)) {
        let result = true;
        visibleIfTag.forEach((item) => {
            if (!visibleIf(item.name, item.value, values)) {
                result = false;
            }
        });
        return result;
    } else {
        return visibleIf(visibleIfTag.name, visibleIfTag.value, values);
    }
}

export const isVisible = (variableName, values, documentV2Template) => {
    let isVisible = false;

    documentV2Template.steps.map(step => {
        step.form.cards.map((card) => {
            if (!card.visibleIf || visibleIf(card.visibleIf.name, card.visibleIf.value, values)) {
                card.rows.map((row) => {
                    if (!row.visibleIf || visibleIf(row.visibleIf.name, row.visibleIf.value, values)) {
                        row.items.map((item) => {
                            requiredFieldsForItem(item).map((_variableName) => {
                                if (_variableName === variableName) {
                                    isVisible = true;
                                }
                            });
                        });
                    }
                })
            }
        });
    });
    return isVisible;
}

export default visibleIf;