import React from 'react';
import {Field} from 'redux-form';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';

import * as pubSubMessages from '../../store/pubSubMessages';

const YesNo = ({name, itemCols }) => {
    const handleYesSelection = () => PubSub.publish(pubSubMessages.YES_SELECTED, name);
    const handleNoSelection = () => PubSub.publish(pubSubMessages.NO_SELECTED, name);

    return (
        <div className={itemCols}>
            <ul className="check-list list-unstyled d-flex flex-wrap flex-row">
                <li>
                    <div className="custom-control custom-radio">
                        <Field id={name + "_yes"} component="input" type="radio" className="custom-control-input" name={name} value="yes" onChange={handleYesSelection}/>
                        <label htmlFor={name + "_yes"} className="custom-control-label p-0 m-0">Yes</label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-radio">
                        <Field id={name + "_no"} component="input" type="radio" className="custom-control-input" name={name} value="no" onChange={handleNoSelection}/>
                        <label htmlFor={name + "_no"} className="custom-control-label p-0 m-0">No</label>
                    </div>
                </li>
            </ul>
        </div>
    )
};

YesNo.propTypes = {
    name: PropTypes.string.isRequired,
    itemCols: PropTypes.string.isRequired
};

export default YesNo;