import React from 'react';
import {Field} from 'redux-form';
import Date3Redux from "./ReduxComponent/Date3";

const Date3 = (props) => {
    return (
        <div className={props.itemCols}>
            <Field component={Date3Redux} {...props} />
        </div>
    );
};

export default Date3;