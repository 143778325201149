import React from 'react';

const InputField = ({input,label,type,placeholder,
                  meta: { touched, error, warning }
        }) => (
            <input type="text"
                   {...input}
                   id={input.name}
                   name={input.name}
                   className={`${input.className} ${error && 'error'}`}
                   placeholder={placeholder}/>
);

export default InputField;