import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import axios from 'axios'

import {useAuth} from "../../utils/auth";

const AdmissionMessage = ({message}) => {
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getAxiosAuthConfig} = useAuth();
    console.log('[AdmissionMessage] ---> ', message);

    const handleConfirmation = () => {
        setProcessing(true);
        axios.get(`/api/patient/admission/message/${message.hash}/confirm`,getAxiosAuthConfig())
            .then(async (response) => {
                navigate(-1)
            })
    }

    const renderBtn = () => {
        if (message.hospitalSettings.patient_confirmMessages) {
            return (
                <button type="button" className="btn btn-primary btn-lg btn-square" onClick={handleConfirmation} disabled={processing}>
                    {message.hospitalSettings.patient_confirmMessages_title}
                </button>
            );
        }
        return <button type="button" className="btn btn-primary btn-lg btn-square" onClick={() => navigate(-1)}>
            Ok
        </button>;
    }

    return (
        <main id="main" className="pt-0 pt-md-5 pb-0 pb-md-6 main-grow">
            <section className="container-small">
                <div className="head-block">
                    <h2>
                        <a onClick={() => navigate(-1)} className="back-link"><i className="icon icon-arrow-left" /></a>
                        <span className="ml-2">{message.title}</span>
                    </h2>
                    {/*{renderTopPager()}*/}
                </div>
                <div className="privacy-policy">
                    <div className="text"  dangerouslySetInnerHTML={{__html: message.message}} />
                </div>
                <div className="row no-gutters pt-3 pt-lg-4 pb-3">
                    <div className="col-12">
                        <ul
                            className="btn-navigation m-0 list-unstyled w-100 d-flex flex-column flex-nowrap flex-sm-row-reverse align-items-sm-center">
                            <li>{renderBtn()}</li>
                            {/*<li>*/}
                            {/*    <a onClick={() => history.goBack()} className="back-link"><i className="icon icon-arrow-left" /> <span*/}
                            {/*        className="ml-2">Go back</span></a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AdmissionMessage;