import React from "react";
import {useSelector} from "react-redux";
import dotProp from 'dot-prop-immutable';

import {GlobalStyle, StyledDiv} from './Theme_StyledComponents';

const Theme = ({children}) => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);
    const {userAdmission} = useSelector(state => state.main);
    if (!loadedThemeId) {
        return children;
    }
    if (theme.windowTitle_enable) {
        document.title = theme.windowTitle.replace('{userAdmission.hospitalTitle}', dotProp.get(userAdmission, 'hospitalTitle', 'Patient Portal'));
    }
    console.log('[Theme]', loadedThemeId, theme);

    return (
            <>
                {/*<Helmet>*/}
                {/*    <title>My Healthe Care</title>*/}
                {/*    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />*/}
                {/*    <meta*/}
                {/*        name="description"*/}
                {/*        content="Access information about your stay via your secure patient portal."*/}
                {/*    />*/}
                {/*    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
                {/*</Helmet>*/}
                <GlobalStyle {...theme}  loadedThemeId={loadedThemeId}/>
                <StyledDiv {...theme} loadedThemeId={loadedThemeId}>
                    {children}
                </StyledDiv>
            </>
    );
}

export default Theme;