import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {change} from 'redux-form';

// TODO: add ability to change first Year

const DateMMYY = props => {
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');

    const dispatch = useDispatch();

    const reduxYear = () => props.input.value && props.input.value.substr(3);
    const reduxMonth = () => props.input.value && props.input.value.substr(0,2);

    if (props.input.value && !year) {
        setYear(reduxYear());
    }
    if (props.input.value && !month) {
        setMonth(reduxMonth());
    }

    console.log('[DateMMYY] render', props.input.value, year, month, reduxYear(), reduxMonth());
    let selectedValue = month + '/' + year;

    useEffect(() => {
        if (year && month && (selectedValue !== props.input.value)) {
            const now = new Date();
            if ((year > now.getFullYear()) || ((parseInt(year) === now.getFullYear()) && (month > now.getMonth()))) {
                props.input.onChange(selectedValue);
                console.log('[DateMMYY] handleChange ', selectedValue);
            } else {
                console.log('[DateMMYY] date in past', (year > now.getFullYear()), (parseInt(year) === now.getFullYear()), (month > now.getMonth()));
                dispatch(change('wizard', props.input.name, null));
                // props.input.onChange('');
            }
        }
    }, [selectedValue]);

    const changeYear = (event) => {
        setYear(event.target.value);
    }
    const changeMonth = (event) => {
        setMonth( event.target.value);
    }

    return (
        <div>
            <div className='date-mmyy-month'>
                <select className="select-mmyy-month" onChange={changeMonth} value={month ? month : reduxMonth()}>
                    <option value="">select month</option>
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
            </div>
            <div className='date-mmyy-year'>
                <select className="select-mmyy-month"  onChange={changeYear} value={year ? year : reduxYear()}>
                    <option value="">select year</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                </select>
            </div>
        </div>
    )
}

export default DateMMYY;