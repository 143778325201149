import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import dotProp from 'dot-prop-immutable';

const InfoTableBlock = ({key, items, title}) => {
    const documentData = useSelector(state => state.main.currentDocument.documentData);

    const renderItem = (item, index) => {
        if (item.variable && !dotProp.get(documentData, item.variable) && item.hideEmptyValues) {
            return null;
        }
        return <div className="row no-gutters " key={index}>
            <div className={`col-6 ${item.cssStyle || ''}`}>
                <span className="label text-default m-lg-0">{item.title}</span>
            </div>
            <div className="col-6 pl-2">
                {item.value && <span className="label m-lg-0">{item.value}</span>}
                {item.variable && <span className="label m-lg-0">{dotProp.get(documentData, item.variable)}</span>}
                {item.variableFormatted &&
                    <span className="label m-lg-0">{item.format.replace('%s', dotProp.get(documentData, item.variableFormatted))}</span>}
                {item.variableBoolean && <span className="label m-lg-0">{documentData[item.variableBoolean] ? 'Yes' : 'No'}</span>}
                {item.fullName && <span
                    className="label m-lg-0">{documentData['name'] || documentData['firstName']} {documentData['surname'] || documentData['lastName']}</span>}
            </div>
        </div>
    }

    return (
        <div className="card mb-2 mb-lg-4" key={key}>
            {title && <div className="card-header">
                <h3 className="mb-0">{title}</h3>
            </div>}
            <div className="card-body">
                {items.map(renderItem)}
            </div>
        </div>
    );
};

export default InfoTableBlock;