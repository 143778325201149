import {useNavigate} from 'react-router-dom';

export const optionId = (option) => {
    if (option.value instanceof String) {
        return option.value.replace(' ', '_');
    } else {
        return option.value;
    }
}

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();

        return (
        <Component
            history={history}
            {...props}
            />
        );
    };

    return Wrapper;
};