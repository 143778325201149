import React from 'react';
import Moment from 'moment';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PropTypes from 'prop-types';

class DatePicker extends React.Component {
    componentDidMount() {
        if (this.refs.datepicker.classList.contains('hasDatePicker')) return;

        $(this.refs.datepicker).datepicker(
            {
                dateFormat: 'dd/mm/yy',
                onSelect: (value) => this.onChange(value ),
            }
        );
    }

    onChange = (value) => this.props.input.onChange(value);

    render() {
        const {input, itemCols, placeholder, ...rest} = this.props;

        const className = rest.className ? rest.className : "form-control form-control-sm datepicker";
        return (
            <input className={className}
                   type="text"
                   id={input.name}
                   placeholder="DD/MM/YYYY"
                   ref="datepicker"
                   name={input.name}
                   onChange={event => event.preventDefault()}
                   value={input.value}
                   autoComplete="off"
            />
        );
    }
}

DatePicker.propTypes = {
    input: PropTypes.object,
    itemCols: PropTypes.string,
    placeholder: PropTypes.string,
};

export default DatePicker;