import axios from "axios";
import {useAuthThunk} from "../../../../utils/auth";

export const loadOptions = (inputValue, callback) => {
    return (dispatch, getState) => {
        console.log('[Document_v2 / Actions] selectAsync.loadOptions', inputValue)
        const {getAxiosAuthConfig} = useAuthThunk(getState);

        const userAdmission = getState().main.userAdmission;
        axios.get(`/api/patient/admission/lifehouse/load-options/${inputValue}`,  getAxiosAuthConfig())
            .then((response) => {
                callback(response.data);
            });
    };
};

export const loadValue = (inputValue, callback) => {
    return (dispatch, getState) => {
        const {getAxiosAuthConfig} = useAuthThunk(getState);
        if (inputValue) {
            axios.get(`/api/patient/admission/lifehouse/load-default-option/${inputValue}`, getAxiosAuthConfig())
                .then((response) => {
                    callback(response.data);
                });
        } else {
            callback([]);
        }
        // console.log('[Document_v2 / Actions] selectAsync.loadValue', inputValue);
        // callback({value: "0000837J", label: 'ROBYN KAYE,0000837J,123 fake street'});
    }
}