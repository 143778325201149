import React,{forwardRef, useEffect} from 'react';
import SignatureCanvas from "react-signature-canvas";
import {Field} from "redux-form";
import {useDispatch, useSelector} from 'react-redux';

import * as actionTypes from '../../store/actions/actionTypes';
import InputField from './InputField';

const Signature = (props, sigPadRef) => {
    const {errors, admissionType} = useSelector(state => state.review);
    const dispatch = useDispatch();

    const handleSignatureEnd = () => {
        let canvas = sigPadRef.current.getTrimmedCanvas();
        if ((canvas.width > 50) && (canvas.height > 50)) {
            dispatch({type: actionTypes.REVIEW_SIG_PAD_DRAW});
            dispatch({type: actionTypes.REVIEW_REMOVE_ERROR, error: '_signature'});
        }
    };

    const clearSignatureNameError = () => dispatch({type: actionTypes.REVIEW_REMOVE_ERROR, error: '_signatureName'});

    const clearSignature = () => {
        dispatch({type: actionTypes.REVIEW_SIG_PAD_RESET});
        sigPadRef.current.clear();
    }

    useEffect(
        () => {
            return () => {
                dispatch({type: actionTypes.REVIEW_SIG_PAD_RESET})
            }
        }, []
    );

    let canvasWidth = 0;
    if (window.innerWidth < 767) {
        canvasWidth = window.innerWidth-100;
    } else if (window.innerWidth < 1260) {
        canvasWidth = 400;
    } else {
        canvasWidth = 500;
    }

    return (
        <div className="card mb-3 signature-card">
            <div className="card-header row">
                <div className="col-12">
                    <h2 className="mb-2">{admissionType.reviewPageTitle}</h2>
                    <p>{admissionType.reviewPageBody}</p>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className={`row no-gutters  ${errors.indexOf('_signature') !== -1?'error':''}`}>
                    <div className="col-12 col-lg-4">
                        <label htmlFor="Signature" className="label m-lg-0">Patient or Guardian’s
                            Signature</label>
                        <div className="btn-holder reset-signature-btn">
                            <button className="btn btn-secondary" type="button" onClick={clearSignature}>Clear signature</button>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <SignatureCanvas penColor='black' canvasProps={{width: canvasWidth, height: 200, className: `sigCanvas`}} ref={sigPadRef} onEnd={handleSignatureEnd}/>
                        <div className="signature-notice-container">
                            <div className="signature-notice">
                                SIGN HERE USING YOUR MOUSE OR FINGER
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row no-gutters  ${errors.indexOf('_signatureName') !== -1?'error':''}`}>
                    <div className="col-12 col-lg-4">
                        <label htmlFor="Guardian" className="label m-lg-0">Patient or Guardian’s full
                            name</label>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="row no-gutters">
                            <div className="col-12 col-lg-12">
                                <Field component={InputField}
                                       onChange={clearSignatureNameError}
                                       type="text"
                                       id="_signatureName"
                                       name="_signatureName"
                                       className="form-control form-control-sm error"
                                       placeholder="Enter name"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(Signature);