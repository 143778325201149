import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

const IfcBlock = ({key, template}) => {
    const documentData = useSelector(state => state.main.currentDocument.documentData);

    const nonZeroAmount = (amount) => {
        if (amount === '0.00') {
            return '-';
        } else {
            return `$${amount}`
        }
    }
    const itemDesc = row => {
        console.log('item desc', row.ifc_itemType.substr(0,13), row);
        if (row.ifc_itemType.substr(0,13) === 'Accommodation') {
            return row.ifc_itemDesc;
        } else {
            return row.ifc_itemCode;
        }

    }

    const renderIfcRow = (row, index) => {
        if ((row.ifc_itemType === 'Theatre Fees') ||
            (row.ifc_rate !== '0.00') || (row.ifc_total !== '0.00') || (row.ifc_fundRebate !== '0.00') || (row.ifc_patientCost !== '0.00')
        ) {
            return (
                <div className="row w-100 no-gutters items-descr" key={index}>
                    <div className="col-12 col-lg-3">
                        <strong className="label m-lg-0">{row.ifc_itemType}</strong><br/><span
                        style={{marginLeft: '10px'}}>{itemDesc(row)}</span>
                    </div>
                    <div className="col-12 col-lg-9 pl-lg-2 items-list">
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Daily Room Rate</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_rate)}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Maximum Cost</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_total)}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Length of Stay</span>
                            <span className="label ml-auto">{row.ifc_expectedLOS}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Fund Rebate</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_fundRebate)}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Patient Cost</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_patientCost)}</span>
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    return (
        <div className="card mb-2 mb-lg-4" key={key}>
            <div className="card-header">
                <h3 className="mb-0">Estimated</h3>
            </div>
            <div className="card-body body-small">
                <div className="row no-gutters pb-0 pb-lg-4">
                    <div className="col-12">
                        <div className="row w-100 no-gutters d-none d-lg-flex items-names mt-2 mb-3">
                            <div className="col-3">
                                <span className="label m-lg-0">Type</span>
                            </div>
                            <div className="col-9 pl-2 items-list">
                                <div className="item d-flex">
                                    <span className="label ml-auto">Daily Room Rate</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Maximum Cost</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Lenght of Stay</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Fund Rebate</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Patient Cost</span>
                                </div>
                            </div>
                        </div>

                        {documentData.adm2_ifc___group.map(renderIfcRow)}

                    </div>

                </div>
                <div className="row no-gutters pt-3 pb-3 pb-lg-2">
                    <div className="col-12">
                        <div className="box">
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Fund Excess</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">{documentData.adm2_patientExcess_str}</span>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">GST</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">${documentData.adm2_ifc_total_GST}</span>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Total Estimated Cost</span>
                                </div>
                                <div className="d-flex value">
                                    <strong className="label m-lg-0">${documentData.adm2_ifc_total_estimatedCost}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IfcBlock;