import React, {useState} from 'react';
import {useSelector} from "react-redux";

const AgreeBtnBlock = ({key, agree_btn_title}) => {
    const documentProcessing = useSelector(state => state.main.documentProcessing);

    return (
        <div className="card controls-block " key={key}>
            <div className="card-body">
                <div className="row no-gutters pt-3 pt-lg-4 pb-3">
                    <div className="col-12">
                        <ul
                            className="btn-navigation m-0 list-unstyled w-100 d-flex flex-column flex-nowrap flex-sm-row-reverse align-items-sm-center">
                            <li>
                                <button type="button" className="btn btn-primary btn-lg btn-square" disabled={documentProcessing}>{agree_btn_title}</button>
                            </li>
                            {/*<li>*/}
                            {/*    <a href="#" className="back-link"><i className="icon icon-arrow-left"></i> <span*/}
                            {/*        className="ml-2">Go back</span></a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgreeBtnBlock;