import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../store/actions/actionTypes';
import {useAuth} from "../../utils/auth";

// this is clone of PreAdmissionView from pre-admissions system
const DocumentIntro = () => {
    const dispatch = useDispatch();
    const template = useSelector(state => state.main.currentDocument.intro.template);
    const {getPatientPortalUrlPrefix} = useAuth();

    const handleContinue = () => dispatch({type: actionTypes.SET_INTRO_DONE});

    return (
        <main id="main">
            <section className="container">
                <div className="pre-admission">
                    <div className="holder">
                        <div dangerouslySetInnerHTML={{__html: template}}></div>
                        <div className="btn-holder">
                            <a className="btn btn-primary btn-lg" onClick={handleContinue}>Continue</a>
                            <a className="btn btn-secondary btn-lg" href={`/${getPatientPortalUrlPrefix()}/tasks`}>Cancel</a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default DocumentIntro;