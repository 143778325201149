import React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import PatientPortal from "./routing/PatientPortal";
import IndexPage from "./routing/IndexPage";
import Theme from "./components/Theme/Theme";
import DevException from "./routing/views/DevException";
import DevTestPage from "./routing/views/DevTestPage";
import ImpersonatePatientPortal from "./routing/ImpersonatePatientPortal";
import {withRouter} from './utils/utils';

function App() {
    return (
        <Theme>
            <Routes>
                <Route path="/dev/exception" element={<DevException />}></Route>
                <Route path="/dev/test" element={<DevTestPage />}></Route>
                <Route path="/i/:impersonateToken/*" element={<ImpersonatePatientPortal />}></Route>
                <Route path="/:hash/*" element={<PatientPortal />}></Route>
                <Route path="/" element={<IndexPage />}></Route>
            </Routes>
        </Theme>
    );
}

export default withRouter(App);
