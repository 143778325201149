import React, {Component, Fragment} from 'react';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import PubSub from "pubsub-js";

import * as actionTypes from '../../../store/actionTypes';
import * as pubSubMessages from "../../../store/pubSubMessages";

class MedicationList extends Component {
    componentDidUpdate = () => {
        if ( this.props.medication && this.props.medicationDose && this.props.medicationFrequency &&
            (this.props.medication[0] !== '') && (this.props.medication[0] !== null) &&
            (this.props.medicationDose[0] !== '') && (this.props.medicationDose[0] !== null) &&
            (this.props.medicationFrequency[0] !== '') && (this.props.medicationFrequency[0] !== null)) {
                PubSub.publish(pubSubMessages.VALIDATION_ERROR_CLEAR, '__medications');
        }
    }

    componentDidMount = () => {
        // TODO: refactor and move it to better place
        const {stateMedicationsCount, medicationsCount, medication, medicationDose, medicationFrequency} = this.props;

        if (stateMedicationsCount === 0) {
            console.log('[MedicationList] updating stateMedicationsCount');
            let newCount = Math.max(stateMedicationsCount, medication ? medication.length : 0, medicationDose ? medicationDose.length : 0, medicationFrequency ? medicationFrequency.length : 0);
            if (newCount === 0) {
                newCount = medicationsCount;
            }
            if (stateMedicationsCount !== newCount) {
                this.props.dispatch({type: actionTypes.WIZARD_SET_MEDICATIONS_COUNT, value: newCount});
            }
        }
    };

    dispatchDeleteMedication = index => {
        // TODO: Refactor to single action
        this.props.dispatch({type: actionTypes.WIZARD_REMOVE_MEDICATION, index});
        this.props.dispatch({type: actionTypes.WIZARD_DEC_MEDICATIONS_COUNT});
    }

    renderMedicationItem = (key, values) => {
        return (
            <Fragment key={key}>
                <div className="col-12 col-lg-3">
                    <label htmlFor={`medication_${key}`} className="m-lg-0">Medication</label>
                </div>
                <div className="col-12 col-lg-9">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-4">
                            <Field id={`medication_${key}`}
                                   component="input"
                                   type="text"
                                   name={`medication[${key}]`}
                                   className="form-control form-control-sm"
                                   placeholder="For ex. aspirin" />
                        </div>
                        <div className="col-12 col-lg-3 border-left">
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor={`medication_dose_${key}`} className="m-lg-0">Dose</label>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <Field id={`medication_dose_${key}`}
                                           component="input"
                                           type="text"
                                           name={`medicationDose[${key}]`}
                                           className="form-control form-control-sm"
                                           placeholder="mg" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 border-left">
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor={`medication_frequency_${key}`} className="m-lg-0">Frequency</label>
                                </div>
                                <div className="col-11 col-lg-4">
                                    <Field id={`medication_frequency_${key}`}
                                           component="input"
                                           type="text"
                                           name={`medicationFrequency[${key}]`}
                                           className="form-control form-control-sm"
                                           placeholder="2x day" />
                                </div>
                                <div className="col-1 col-lg-2 text-center">
                                    <a onClick={this.dispatchDeleteMedication.bind(this, key)} className="upload-link cursor-pointer"><span className="icon icon-close" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    render = () => {
        return (
            <>
                {[...Array(this.props.stateMedicationsCount)].map((value, index) => this.renderMedicationItem(index, {}))}
            </>
        );
    }
}

const mapStateToProps = state => ({
    stateMedicationsCount: state.wizard.medicationsCount,
    medication: state.form.wizard.values.medication,
    medicationDose: state.form.wizard.values.medicationDose,
    medicationFrequency: state.form.wizard.values.medicationFrequency,
});

export default connect(mapStateToProps)(MedicationList);