import PubSub from "pubsub-js";
import * as dotProp from "dot-prop-immutable";

import * as pubSubMessages from "../../components/Document_v2/store/pubSubMessages";
import * as actionTypes from "../actions/actionTypes";

// used to store error texts from customValidation and reviewValidation

const initialState = {
    // props are created dynamically
    // not sure, if its "correct" way though
    errors: {},
    complexErrors: {},
};

const reducer = (state = initialState, action) => {
    let newState = state;
    switch(action.type) {
        case "@@redux-form/CHANGE":
            if (action.meta.form === 'wizard') {
                // patch for ACA-161
                PubSub.publish(pubSubMessages.VALIDATION_ERROR_CLEAR, action.meta.field);

                if (state.complexErrors[action.meta.field]) {
                    state.complexErrors[action.meta.field].map(_key => {
                        newState = dotProp.delete(newState, `errors.${_key}`);
                        newState = dotProp.delete(newState, `complexErrors.${_key}`);
                        PubSub.publish(pubSubMessages.VALIDATION_ERROR_CLEAR, _key);
                    });
                } else if (state.errors[action.meta.field]) {
                    newState = dotProp.delete(newState, `errors.${action.meta.field}`);
                }
            }
            return newState
        case actionTypes.CUSTOM_VALIDATION_SET_ERROR:
            return dotProp.set(state, `errors.${action.variable}`, action.error);
        case actionTypes.CUSTOM_VALIDATION_SET_COMPLEX_ERROR:
            action.keys.map(key => {
                newState = dotProp.set(newState, `errors.${key}`, action.error);
                if (action.keys.length > 1) {
                    newState = dotProp.set(newState, `complexErrors.${key}`, action.keys);
                }
            });

            return newState
    }
    return state;
};

export default reducer;