import React, {useEffect} from 'react';
import YesNoRow from "./Rows/YesNo";
import Dummy from "../../Dummy";
import InputRow from "./Rows/Input";
import jcf from 'jcf/js/jcf';
import DatePickerRow from "./Rows/DatePicker";
import AgreeCheckboxesRow from "./Rows/AgreeCheckboxes";
import AgreeBtnRow from "./Rows/AgreeBtn";
import {Field} from "redux-form";
import SignatureRow from "./Rows/Signature";
import TextRow from "./Rows/Text";
import RadioRow from "./Rows/Radio";
import CheckRow from "./Rows/Check";
import VisibleIf from "./VisibleIf";
import TextAreaRow from "./Rows/TextArea";

const FormFields = {
    YesNo_row: YesNoRow,
    AgreeCheckboxes_row: AgreeCheckboxesRow,
    Input_row: InputRow,
    Date_row: DatePickerRow,
    Agree_row: AgreeBtnRow,
    Signature_row: SignatureRow,
    Text_row: TextRow,
    Radio_row: RadioRow,
    Check_row: CheckRow,
    TextArea_row: TextAreaRow,
};

const required = value => value ? undefined : 'Required';

const requiredCheckbox = value => {
    console.log('Validate requiredCheckbox', value);
    if (!value) return 'Required';

    let trueValues = [];
    Object.keys(value).forEach(key => {
        if (value[key]) {
            trueValues.push(key);
        }
    });
    console.log('True values', trueValues);
    return trueValues.length ? undefined : 'Required';
}

const Form = ({title, rows}) => {

    // useEffect(() => {
    //     enableJCF();
    // }, []);
    // const enableJCF = () => {
    //     jcf.setOptions('Select', {
    //         wrapNative: false,
    //         wrapNativeOnMobile: false,
    //         fakeDropInBody: true,
    //     });
    //     jcf.replaceAll();
    // };

    const renderRow = (row, index) => {
        const FieldComponent = FormFields[row.type];
        const validation = row.type === 'Check_row' ? requiredCheckbox : required;
        const rowJsx = <FieldComponent key={index} {...row} validate={row.required ? [validation] :[] }/>;

        if (row.visibleIf) {
            return <VisibleIf {...row.visibleIf} key={index} >{rowJsx}</VisibleIf>
        } else {
            return rowJsx;
        }
    }

    return (
        <div className="card mb-lg-4">
            <div className="card-header">
                <h3 className="mb-0">{title}</h3>
            </div>
            <div className="card-body">
                {rows.map(renderRow)}
            </div>
        </div>
    );
};

export default Form;