import React from 'react';
import {Field} from 'redux-form';
import Date4Redux from "./ReduxComponent/Date4";

const Date4 = (props) => {
    return (
        <div className={props.itemCols}>
            <Field component={Date4Redux} {...props} />
        </div>
    );
};

export default Date4;