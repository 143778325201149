import React from 'react';
import {StickyContainer, Sticky} from 'react-sticky';
import {useSelector, useDispatch} from 'react-redux';

import SqreenLogo from '../../../assets/images/logo-sqreen.png';
import ProgressPanelItem from "./ProgressPanelItem";
import ProgressPanelReviewItem from "./ProgressPanelReviewItem";
// import contactLink from "../../skin/contactLink";

import * as actionTypes from '../../../store/actions/actionTypes';

const ProgressPanelDesktop = ({onNextStep}) => {
    const dispatch = useDispatch();
    const steps = useSelector(state => state.progressPanel.steps);

    // Dev code
    const dev = () => {
        // dispatch({type: actionTypes.CUSTOM_VALIDATION_SET_ERROR, variable: 'medicare_number', error:'Wrong format - should be 10 digits number'});
    }


    return (
        <StickyContainer className="sidebar col-12 col-md-4 col-lg-3 order-md-2">
            <Sticky topOffset={-100} bottomOffset={100}>
                {
                    ({style, isSticky}) => {
                        // console.log("style", style);
                        if (isSticky) {
                            style = {...style, top: style.top+100};
                        }
                        return (
                        <section className="widget step-widget" style={style}>
                            <ul className="step-list">
                                {steps.map((item, index) => <ProgressPanelItem onNextStep={onNextStep} item={item} index={index} key={index}/>)}
                                <ProgressPanelReviewItem onNextStep={onNextStep} />
                            </ul>
                            {/*<div className="btn-holder d-flex flex-row flex-wrap py-1 mx-n2">*/}
                            {/*    <a href={contactUrl} className="btn btn-secondary flex-grow-1 m-1 px-2"  target="_blank">*/}
                            {/*        <i className="icon icon-chat"/> Contact Us*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            {/*<div className="logo-wrap text-center border-top pt-3 mt-3 pt-lg-4 mt-lg-4">*/}
                            {/*    <a href="#" className="logo d-inline-block" onClick={dev}>*/}
                            {/*        <img src={SqreenLogo} width="109" height="36" alt="Protected by Sqreen"/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </section>);
                    }
            }
            </Sticky>
        </StickyContainer>
    );
}

export default ProgressPanelDesktop;