import React from 'react';
import {Field} from 'redux-form';

const renderField = (field) => {
    const {input, label, placeholder, meta, visibleIf, required} = field;
    return (<div className={`row no-gutters ${meta.touched && meta.error && "form-error"}`} id={input.name}>
        <div className="col-12 col-lg-6">
            <span className="label m-lg-0">{label}{required && <sup>*</sup>}</span>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1 pl-lg-4">
            <ul className="check-list check-btns list-unstyled d-flex flex-wrap flex-row">
                <li>
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" id={input.name + "_yes"} {...input} value="yes" />
                        <label className="custom-control-label p-lg-0 m-lg-0" htmlFor={input.name + "_yes"}>Yes</label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" id={input.name + "_no"} {...input} value="no"/>
                        <label className="custom-control-label p-lg-0 m-lg-0" htmlFor={input.name + "_no"}>No</label>
                    </div>
                </li>
            </ul>
        </div>
    </div>);
}

const YesNo = props => <Field {...props} component={renderField}/>;

export default YesNo;