import * as actionTypes from '../actions/actionTypes';

const initialState = {
    steps: [],
    currentStep: 0,
    allStepsEnabled: false,
    documentHash: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROGRESS_PANEL_INIT:
            return {
                ...state,
                steps: action.steps,
                currentStep: action.currentStep,
                allStepsEnabled: action.allStepsEnabled,
                documentHash: action.documentHash,
            }
        case actionTypes.PROGRESS_PANEL_CHANGE_STEP:
            return {
                ...state,
                currentStep: action.currentStep,
            }
        case actionTypes.PROGRESS_PANEL_ALL_STEPS_ENABLED:
            return {
                ...state,
                allStepsEnabled: action.enable,
            }
    }
    return state;
}

export default reducer;