import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as actionTypes from "../../store/actions/actionTypes";

const DevException = () => {
    const [done, setDone] = useState(false);
    const [response, setResponse] = useState('');

    useEffect(() => {
        axios.get('/dev/exception')
            .then((response) => {
                setDone(true);
                setResponse(response.data);
            })
    }, []);

    if (done) {
        return <>
                <h1>Dev/Exception - Done!</h1>
                <p>Got response - {response}</p>
            </>
    } else {
        return <h1>Dev/Exception - ...</h1>
    }
}

export default DevException;