import cloneDeep from 'lodash/cloneDeep';

import * as actionTypes from "../actions/actionTypes";

const initialState = {
    documentHash: null,
    ready: false,
    admissionType: {},
    steps: [],
    values: {},
    files: [],
    sigPadFilled: false,
    errors: [],
    admissionDocumentType: {},
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.REVIEW_ADD_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.error]
            };
        case actionTypes.REVIEW_REMOVE_ERROR:
            return {
                ...state,
                errors: state.errors.filter(item => item !== action.error)
            };
        case actionTypes.REVIEW_INIT:
            return {
                ...initialState,
                ready: true,
                admissionType: cloneDeep(action.admissionType),
                steps: action.admissionType.steps.map((step) => step.title),
                values: {...action.vars},
                documentHash: action.documentHash,
                files: action.files,
                hospital: action.hospital,
                admissionDocumentType: action.admissionDocumentType,
            };
        case actionTypes.REVIEW_SIG_PAD_DRAW:
            return {
                ...state,
                sigPadFilled: true,
            };
        case actionTypes.REVIEW_SIG_PAD_RESET:
            return {
                ...state,
                sigPadFilled: false,
            };
        case actionTypes.REVIEW_RESET:
            return {
                ...state,
                ...initialState
            }
    }
    return state;
};

export default reducer;