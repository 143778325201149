import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

const LifehouseIfcBlock = ({key, template}) => {
    const documentData = useSelector(state => state.main.currentDocument.documentData);

    const nonZeroAmount = (amount) => {
        if (amount === '0.00') {
            return '-';
        } else {
            return `$${amount}`
        }
    }

    const renderIfcRow = (row, index) => {
        return (
            <div className="row w-100 no-gutters items-descr" key={index}>
                <div className="col-12 col-lg-5">
                    <span className="label m-lg-0">{row.itemDescription}</span><br/>
                    {/*<span style={{marginLeft: '10px'}}></span>*/}
                </div>
                <div className="col-12 col-lg-7 pl-lg-2 items-list">
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Item</span>
                        <span className="label ml-auto">{row.itemNo}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Quantity</span>
                        <span className="label ml-auto">{row.quantity}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Charge</span>
                        <span className="label ml-auto">{nonZeroAmount(row.amount)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Benefit</span>
                        <span className="label ml-auto">{nonZeroAmount(row.benefit)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Out-of-Pocket</span>
                        <span className="label ml-auto">{nonZeroAmount(row.outOfPocket)}</span>
                    </div>
                </div>
            </div>
        );
    }

    const renderCopay = () => {
        if ((documentData.lifehouse_ifc.paymentType !== "COPAY") || (documentData.lifehouse_ifc.coPay === "0.00")) {
            return null;
        }
        return (
            <div className="row w-100 no-gutters items-descr" key="copay">
                <div className="col-12 col-lg-5">
                    <span className="label m-lg-0">Patient Copay</span><br/>
                    {/*<span style={{marginLeft: '10px'}}></span>*/}
                </div>
                <div className="col-12 col-lg-7 pl-lg-2 items-list">
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Item</span>
                        <span className="label ml-auto">Copay</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Quantity</span>
                        <span className="label ml-auto">&nbsp;</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Charge</span>
                        <span className="label ml-auto">&nbsp;</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Benefit</span>
                        <span className="label ml-auto">-{nonZeroAmount(documentData.lifehouse_ifc.coPay)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Out-of-Pocket</span>
                        <span className="label ml-auto">{nonZeroAmount(documentData.lifehouse_ifc.coPay)}</span>
                    </div>
                </div>
            </div>
        );
    }
    const renderExcess = () => {
        if ((documentData.lifehouse_ifc.paymentType !== "EXCESS") || (documentData.lifehouse_ifc.excess === "0.00")) {
            return null;
        }
        return (
            <div className="row w-100 no-gutters items-descr" key="excess">
                <div className="col-12 col-lg-5">
                    <span className="label m-lg-0">Patient Excess</span><br/>
                    {/*<span style={{marginLeft: '10px'}}></span>*/}
                </div>
                <div className="col-12 col-lg-7 pl-lg-2 items-list">
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Item</span>
                        <span className="label ml-auto">Excess</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Quantity</span>
                        <span className="label ml-auto">&nbsp;</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Charge</span>
                        <span className="label ml-auto">&nbsp;</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Benefit</span>
                        <span className="label ml-auto">-{nonZeroAmount(documentData.lifehouse_ifc.excess)}</span>
                    </div>
                    <div className="item d-flex">
                        <span className="label text-default text-left d-lg-none">Out-of-Pocket</span>
                        <span className="label ml-auto">{nonZeroAmount(documentData.lifehouse_ifc.excess)}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="card mb-2 mb-lg-4" key={key}>
            <div className="card-header">
                <h3 className="mb-0">Estimated</h3>
            </div>
            <div className="card-body body-small">
                <div className="row no-gutters pb-0 pb-lg-4">
                    <div className="col-12">
                        <div className="row w-100 no-gutters d-none d-lg-flex items-names mt-2 mb-3">
                            <div className="col-5">
                                <span className="label m-lg-0">Description</span>
                            </div>
                            <div className="col-7 pl-2 items-list">
                                <div className="item d-flex">
                                    <span className="label ml-auto">Item</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Quantity</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Charge</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Benefit</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Out-of-Pocket</span>
                                </div>
                            </div>
                        </div>

                        {documentData.lifehouse_ifc.details.map(renderIfcRow)}
                        {renderCopay()}
                        {renderExcess()}
                    </div>

                </div>
                <div className="row no-gutters pt-3 pb-3 pb-lg-2">
                    <div className="col-12">
                        <div className="box">
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Charge</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">${documentData.lifehouse_ifc.totalAmount}</span>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Benefit</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">${documentData.lifehouse_ifc.benefit}</span>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Out-of-Pocket</span>
                                </div>
                                <div className="d-flex value">
                                    <strong className="label m-lg-0">${documentData.lifehouse_ifc.totalToBePaid}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifehouseIfcBlock;