import React, {useEffect} from 'react';
import axios from 'axios'
import {useSelector, useDispatch} from "react-redux";
import { useParams } from 'react-router-dom';

import Loading from "../../components/Loading";
import * as actionTypes from '../../store/actions/actionTypes';
import Document from "../../components/Document/Document";
import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Message from "../../components/Message";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import Document_v2 from '../../components/Document_v2/Document_v2';
import Theme_Footer from "../../components/Theme/Theme_Footer";
import {useAuth} from "../../utils/auth";
import DocumentIntro from "../../components/DocumentIntro/DocumentIntro";

const DocumentPage = () => {
    const {loading, currentDocument, loadingError, introDone} = useSelector(state => state.main);
    const {getAxiosAuthConfig} = useAuth();
    const {documentHash, documentPage} = useParams();
    console.log('[DocumentPage] ', documentHash, documentPage);
    const dispatch = useDispatch();

    const fetchData = async () =>  {
        console.log('[DocumentPage] fetchData()');
        axios.get(`/api/patient/admission/document/${documentHash}`,getAxiosAuthConfig())
            .then(async (response) => {
                if (response.data.document) {
                    console.log('[DocumentPage] Got document data', response.data.document);
                    await dispatch({type: actionTypes.SET_DOCUMENT, document: response.data.document});
                    await dispatch({type : actionTypes.LOADING_DONE});
                } else {
                    await dispatch({type: actionTypes.SET_DOCUMENT_LOADING_ERROR, error: response.data.error});
                    console.log('[DocumentPage] Got error', response.data);
                }
            })
    };

    useEffect(() => {
        fetchData()
    }, []);

    const renderDocument = () => {
        if (loadingError) {
            return <Message message={loadingError} />;
        } else if (currentDocument) {
            if (currentDocument.intro.show && !introDone) {
                return <DocumentIntro />;
            } else {
                switch (currentDocument.yamlTemplate_version) {
                    default:
                    case 1:
                        return <Document documentPage={documentPage} initialValues={currentDocument.documentData}/>;
                    case 2:
                        return <Document_v2 documentPage={documentPage}/>;
                }
            }
        } else {
            return <Loading />;
        }
    }

    return (
        <div id="wrapper">
            <header className="header sticky-header" id="document_header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>
            {loading ? <Loading /> : renderDocument()}
            <Theme_Footer />
        </div>
    );
}

export default DocumentPage;