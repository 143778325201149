import React from 'react';
import {Field} from 'redux-form';

import FormLabel from '../Label';
import FormInput from '../Input';
import FormDate from '../DatePicker';

const MedicationDetails = (props) => {
    return (
        <>
            <FormLabel required={props.required}
                       htmlFor={`${props.namePrefix}name`}
                       label="Name of Medication"
                       labelCols="col-12 col-lg-5"/>
            <FormInput {...props}
                       itemCols="col-12 col-lg-7"
                       name={`${props.namePrefix}name`}
                       placeholder="Enter name of medication"/>
            <FormLabel required={props.required}
                       htmlFor={`${props.namePrefix}lastDate`}
                       label="Date last taken"
                       labelCols="col-12 col-lg-5"/>
            <FormDate itemCols="col-12 col-lg-2"
                       name={`${props.namePrefix}lastDate`}
                       placeholder="MM/DD/YYYY"/>

            <div className="col-12 col-lg-5">
                <div className="custom-control custom-checkbox">
                    <Field name={`${props.namePrefix}stillTaking`} id={`check_${props.namePrefix}stillTaking`} component="input" type="checkbox"  className="custom-control-input" />
                    <label htmlFor={`check_${props.namePrefix}stillTaking`} className="custom-control-label p-0 m-0">Still taking</label>
                </div>
            </div>
            <div className="col-12">
                <span
                    className="text px-3 pt-1 pb-2">If you take more than one, please list details in chart below</span>
            </div>
        </>
    );
}

export default MedicationDetails;