import React from "react";
import {useSelector} from "react-redux";

const Theme_SupportPhone = () => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    if ((loadedThemeId !== false) && theme.patientPortal_supportPhone_enabled) {
        return (
            <nav className="navbar navbar-light d-flex justify-content-end">
                <a href={`tel:${theme.patientPortal_supportPhone}`}><i className="icon icon-phone"/> <span
                    className="text-dark ml-2 d-none d-lg-inline">{theme.patientPortal_supportPhone}</span></a>
            </nav>
        )
    }

    return null;
}

export default Theme_SupportPhone;