import React from 'react';
import {Field} from 'redux-form';
import Row from "../Row";

const renderField = (field) => {
    const {input, label, placeholder, meta, visibleIf, required} = field;

    return (
        <Row {...field}>
            <div className="col-12 col-lg-4">
                <label htmlFor={input.name} className="m-0">{label}</label>
            </div>
            <div className="col-12 col-lg-8">
                <textarea
                       id={input.name}
                       className="form-control form-control-sm"
                       placeholder={placeholder}
                       autoComplete={Math.random().toString(36).substring(2, 15)}
                       {...input}
                />
            </div>
        </Row>
    );
};

const TextArea = props => <Field {...props} component={renderField}/>;

export default TextArea;