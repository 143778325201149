import React from 'react';
import {Field} from 'redux-form';

import FormLabel from '../Label';
import FormDate from '../DatePicker';
import FormInput from '../Input';

const DateInput = (props) => {
    const labelCols2 = props.labelCols2 || "col-12 col-lg-4";
    const itemCols2 = props.itemCols2 || "col-12 col-lg-8 border-left";
    const labelCols3 = props.labelCols3 || "col-12 col-lg-6";
    const itemCols3 = props.itemCols3 || "col-12 col-lg-6";

    return (
        <>
            <FormLabel htmlFor={props.name1}
                       label={props.label1}
                       labelCols={props.labelCols}/>

            <div className={props.itemCols}>
                <div className="row ">
                    <FormDate itemCols={labelCols2}
                               name={props.name1}
                               placeholder={props.placeholder1}/>
                    <div className={itemCols2}>
                        <div className="row no-gutters ">
                            <FormLabel htmlFor={props.name2}
                                       label={props.label2}
                                       labelCols={labelCols3}/>
                            <FormInput {...props}
                                       itemCols={itemCols3}
                                       name={props.name2}
                                       placeholder={props.placeholder2}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DateInput;