import {useSelector} from "react-redux";

export const Theme_ReactSelectAsync = () => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    const colorUI = loadedThemeId && theme.color_enable && theme.color_UI ? theme.color_UI : '#00aff3';
    const colorBase = loadedThemeId && theme.color_enable && theme.color_baseText ? theme.color_baseText : '#656565';
    const colorDefaultText = loadedThemeId && theme.color_enable && theme.color_baseText ? theme.color_baseText : '#325885';

    return {
        menu: () => ({
            backgroundColor: "hsl(0, 0%, 100%)",
            // borderRadius: 4,
            // boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
            // boxSizing: "border-box",
            label: "menu",
            marginBottom: 8,
            marginTop: 8,
            position: "absolute",
            top: "100%",
            width: "100%",
            zIndex: 1,
            border: `1px solid ${colorUI}`
        }),
        // indicatorsContainer: () => ({}),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            borderBottom: false,
            color: isSelected || isFocused ? '#FFF' : colorBase,
            backgroundColor: isSelected || isFocused ? colorUI : '#FFF',
            // padding: 20,
        }),
        control: (providedProps, state) => {
            // dump(providedProps, state)
            const {borderWidth, borderColor, boxShadow, boxSizing, ...otherProps} = providedProps;
            return ({
                ...otherProps,
                borderWidth: 0,
            })
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition, color: colorDefaultText, margin: 0 };
        },
        placeholder: (provided, state) => {
            return { ...provided, color: colorDefaultText, margin: 0 };
        },
        valueContainer: (provided, state) => {
            return { ...provided, padding: 0 };
        },
        clearIndicator: (provided, state) => {
            return { ...provided, fill: "#93a4b8" };
        }
    }
}

export const Theme_ReactSelectAutocomplete = () => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    const colorUI = loadedThemeId && theme.color_enable && theme.color_UI ? theme.color_UI : '#00aff3';
    const colorBase = loadedThemeId && theme.color_enable && theme.color_baseText ? theme.color_baseText : '#656565';
    const colorDefaultText = loadedThemeId && theme.color_enable && theme.color_baseText ? theme.color_baseText : '#325885';

    return {
        menu: () => ({
            backgroundColor: "hsl(0, 0%, 100%)",
            // borderRadius: 4,
            // boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
            // boxSizing: "border-box",
            label: "menu",
            marginBottom: 8,
            marginTop: 8,
            position: "absolute",
            top: "100%",
            width: "100%",
            zIndex: 1,
            border: `1px solid ${colorUI}`
        }),
        indicatorsContainer: () => ({}),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            borderBottom: false,
            color: isSelected || isFocused ? '#FFF' : colorBase,
            backgroundColor: isSelected || isFocused ? colorUI : '#FFF',
            // padding: 20,
        }),
        control: (providedProps, state) => {
            // dump(providedProps, state)
            const {borderWidth, borderColor, boxShadow, boxSizing, ...otherProps} = providedProps;
            return ({
                ...otherProps,
                borderWidth: 0,
            })
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition, color: colorDefaultText, margin: 0 };
        },
        placeholder: (provided, state) => {
            return { ...provided, color: colorDefaultText, margin: 0 };
        },
        valueContainer: (provided, state) => {
            return { ...provided, padding: 0 };
        },
        clearIndicator: (provided, state) => {
            return { ...provided, fill: "#93a4b8" };
        }
    }
}