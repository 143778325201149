import React, {Component} from 'react';
import PubSub from 'pubsub-js';
import {connect} from 'react-redux';

import * as actionTypes from "../../../store/actionTypes";

const MedicationAdd = (props) => {
    const clickHandler = (e) => {
        e.preventDefault();
        props.dispatch({type: actionTypes.WIZARD_INC_MEDICATIONS_COUNT});
    };

    return (
        <>
            <div className="col-12 px-3 py-2 px-lg-2">
                <a href="#" className="btn btn-secondary d-block w-100" onClick={clickHandler}><i className="icon icon-plus" /> Add row</a>
            </div>
        </>
    );
};

export default connect()(MedicationAdd);