import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loadedThemeId: null,
    theme: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_THEME_AND_HOSPITAL_CONFIG:
            return {
                ...state,
                loadedThemeId: action.theme.id,
                theme: action.theme,
            };
    }
    return state;
}

export default reducer;