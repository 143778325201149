import React from "react";
import loadingGif from "../assets/images/loader.gif";
import {useSelector} from "react-redux";

const Loading = () => {
    const loadingText = useSelector(state => state.main.loadingText);

    return (
        <div className="preloader">
            <div className="preloader-text">{loadingText}</div>
            <img src={loadingGif} alt="Loading"/>
        </div>
    );
}

export default Loading;