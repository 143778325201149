import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as _ from 'underscore';
import {change} from 'redux-form';

import CustomList_3cells from "./CustomList_3cells";
import CustomList_2cells from "./CustomList_2cells";
import CustomList_4cells from "./CustomList_4cells";

const CustomList = (props) => {
    const form = useSelector(state => state.form);
    const dispatch = useDispatch();

    const counterVar = () => `${props.namePrefix}_count`;
    const rowsCount = () => form.wizard.values[counterVar()];

    if (!rowsCount()) {
        dispatch(change('wizard', counterVar(), props.defaultRowsCount || 3));
        return null;
    } else {
        const renderRow = (key) => {
            switch (props.rowTemplate) {
                case "2cells":
                    return <CustomList_2cells key={key} config={props} rowKey={key} last={(key+1) === rowsCount()}/>;
                case "3cells":
                    return <CustomList_3cells key={key} config={props} rowKey={key} last={(key+1) === rowsCount()}/>;
                case "4cells":
                    return <CustomList_4cells key={key} config={props} rowKey={key} last={(key+1) === rowsCount()}/>;
            }

        }

        const renderList = () => _.range(rowsCount()).map((value, index) => renderRow(index));
        const renderError = () => {
            if (props.error && props.customError) {
                return <div className="col-12 col-lg-12 custom-list-error ">{props.customError}</div>
            }
            return null;
        }


        return <>
                {renderError()}
                {renderList()}
            </>;
    }
}

export default CustomList;