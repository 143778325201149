import {visibleIfArray} from "./visibleIf";
import dotProp from 'dot-prop-immutable';

export const requiredFieldsForItem = item => {
    let requiredFields = [];
    switch (item.type) {
        case "BodyMassIndex":
            requiredFields.push('bodyMass_height');
            requiredFields.push('bodyMass_weight');
            break;
        case "DVACardColour":
            requiredFields.push('DVACardColour');
            requiredFields.push('DVACard_expiry');
            break;
        case "SuburbStatePostcode":
            requiredFields.push(`${item.namePrefix}suburb`);
            requiredFields.push(`${item.namePrefix}state`);
            requiredFields.push(`${item.namePrefix}postcode`);
            break;
        case "MedicationList":
            requiredFields.push(`__medications`);
            break;
        case "MedicationDetails":
            requiredFields.push(`${item.namePrefix}name`);
            requiredFields.push(`${item.namePrefix}lastDate`);
            break;
        case "DateInput":
        case "DoubleInput":
            requiredFields.push(item.name1);
            requiredFields.push(item.name2);
            break;
        case "Checkboxes":
        case "Radio":
        case "Date":
        case "Date2":
        case "Date3":
        case "DateMMYY":
        case "Date4":
        case "YesNo":
        case "Input":
        case "Select":
        case "SelectAsync":
        case "Textarea":
        case "SelectAutocomplete":
            requiredFields.push(item.name);
            break;
        case "CustomList":
            requiredFields.push(`${item.namePrefix}_count`);
            break;
        case "GooglePlacesAddress":
            if (item.name) {
                requiredFields.push(item.name);
            } else {
                requiredFields.push(`${item.namePrefix}address`);
            }
            break;
    }
    return requiredFields;
};

const emptyValue = (values, key) => {
    const value = dotProp.get(values, key, false)
    if (value instanceof Array) {
        const notEmptyValues = value.filter(elem => !!elem);
        // console.log('[emptyValue] !notEmptyValues.length', !notEmptyValues.length);
        return !notEmptyValues.length;
    } else if (value instanceof Object) {
        const notEmptyProps = Object.keys(value).filter(elem => {
            // console.log('[emptyValue] LOOP ', elem, value[elem], !!value[elem]);
            return !!value[elem];
        });
        // console.log('[emptyValue] !notEmptyProps.length', value, notEmptyProps, !notEmptyProps.length);
        return !notEmptyProps.length;
    } else {
        // console.log('[emptyValue] !value', value);
        return !value;
    }
}

export const parseRequiredFields = (formStructure, values) => {
    const requiredFields = [];
    // console.log('[parseRequiredFields]', formStructure, values);

    formStructure.cards.map((card) => {
        if (!card.visibleIf || visibleIfArray(card.visibleIf,  values)) {
           card.rows.map((row) => {
               if (!row.visibleIf || visibleIfArray(row.visibleIf, values)) {
                   row.items.map((item) => {
                       if (item.required === true) {
                           requiredFieldsForItem(item).map((item) => requiredFields.push(item));
                       } else if ((item.required !== false) && (item.required !== undefined)) {
                           console.error('Error in form mapping. Wrong value for required attribute - ', item);
                       }

                       if (item.requiredIfNotEmpty && values && dotProp.get(values.values, item.requiredIfNotEmpty, false)) {
                           requiredFieldsForItem(item).map((item) => requiredFields.push(item));
                           // console.log('[parseRequiredFields] requiredIfNotEmpty value ', dotProp.get(values.values, item.requiredIfNotEmpty, false));
                       }

                       if (item.requiredIfEmpty && values && emptyValue(values.values, item.requiredIfEmpty)) {
                           requiredFieldsForItem(item).map((item) => requiredFields.push(item));
                           // console.log('[parseRequiredFields] requiredIfNotEmpty value ', dotProp.get(values.values, item.requiredIfNotEmpty, false));
                       }
                   })
               }
            })
        }
    });

    // console.log('[parseRequiredFields] done', requiredFields);
    return requiredFields;
};

export const parseRequiredFieldsForAdmissionType = (admissionType, values) => {
    let requiredFields = [];
    admissionType.steps.map(step => {
        requiredFields = [...requiredFields, ...parseRequiredFields(step.form, values)];
    })

    return requiredFields;
};

export const isAllRequiredFieldsFilled = (admissionType, values) => {
    const requiredFields = parseRequiredFieldsForAdmissionType(admissionType, values);
    let allRequiredFieldsFilled = true;

    requiredFields.forEach((item) => {
        if((Object.keys(values).indexOf(item) === -1) || (values[item] === '') || (values[item] === null)) {
            console.log('Required field is not set', item);

            allRequiredFieldsFilled = false;
        }
    });
    return allRequiredFieldsFilled;
};

export default parseRequiredFields;