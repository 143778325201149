import React from 'react';
import {Field} from 'redux-form';
import ReduxGooglePlacesAddress from "./ReduxComponent/ReduxGooglePlacesAddress";

const GooglePlacesAddress = ({name, namePrefix, itemCols, value, options, placeholder, targetSuburb, targetState, targetPostcode, requiredNumber=false, required = false, targetCountry = null, restrictCountry = 'au'}) => {
    return (
        <div className={itemCols}>
            <Field
                className="sm"
                name={namePrefix?`${namePrefix}address`:name}
                value={value}
                required={required}
                component={ReduxGooglePlacesAddress}
                options={options}
                placeholder={placeholder}
                targetSuburb={namePrefix?`${namePrefix}suburb`:targetSuburb}
                targetState={namePrefix?`${namePrefix}state`:targetState}
                targetPostcode={namePrefix?`${namePrefix}postcode`:targetPostcode}
                requiredNumber={requiredNumber}
                targetCountry={targetCountry}
                restrictCountry={restrictCountry}
            />
        </div>
    );
};

export default GooglePlacesAddress;