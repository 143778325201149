import React, {useEffect} from 'react';
import axios from 'axios'
import {useSelector, useDispatch} from "react-redux";
import { useParams, Link } from 'react-router-dom';

import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import {useAuth} from "../../utils/auth";
import Theme_Footer from "../../components/Theme/Theme_Footer";
import Loading from "../../components/Loading";

const StripePaymentCompletedPage = () => {
    const {getAxiosAuthConfig, getPatientPortalUrlPrefix, getAuthorizationTokenInput} = useAuth();
    const {loadedThemeId, theme} = useSelector(state => state.theme)
    const {apiHash} = useParams();
    console.log('[StripePaymentCompletedPage] ', apiHash);
    const dispatch = useDispatch();
    const formRef = React.useRef(null);

    const sendPaymentStatusUpdate = async () =>  {
        console.log('[StripePaymentCompletedPage] fetchData()');
        axios.get(`/api/patient/stripe-payment/complete/${apiHash}`,getAxiosAuthConfig())
            .then(async (response) => {
                console.log('[StripePaymentCompletedPage] complete update response', response.data);
                // await dispatch({type: actionTypes.SET_DOCUMENT, document: response.data.document});
            })
    };
    const viewReceipt = (event) => {
        formRef.current.submit();
        event.preventDefault();
    }

    useEffect(() => {
        sendPaymentStatusUpdate()
    }, []);

    if (!loadedThemeId) {
        return <Loading />
    }

    return (
        <div id="wrapper">
            <header className="header sticky-header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>

            <main id="main" className="pt-4 pt-md-5 pb-0 pb-md-6">
                <div className="container-simple">
                    <section className="tasks-block payment-completed">
                        <div className="head-block head-simple">
                            <h2>Payment Successful</h2>
                        </div>
                        {/* TODO: add download receipt */}
                        <Link to={`/${getPatientPortalUrlPrefix()}/tasks`} className="btn btn-primary btn-lg btn-square">Return home</Link>
                        &nbsp;<a href="#" className="btn btn-primary btn-lg btn-square" onClick={viewReceipt}>Download receipt</a>
                        <div style={{display: 'none'}}>
                            <form action={`${process.env.REACT_APP_API_PATH}/api/patient/stripe-payment/receipt/${apiHash}`} method="post"
                                  ref={formRef}>
                                {getAuthorizationTokenInput()}
                            </form>
                        </div>
                    </section>
                </div>
            </main>

            <Theme_Footer />
        </div>
    );
}

export default StripePaymentCompletedPage;