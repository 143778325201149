import React from 'react';

import HtmlBlock from "./Blocks/HtmlBlock";
import AgreeBtnBlock from "./Blocks/AgreeBtnBlock";
import InfoTableBlock from "./Blocks/InfoTableBlock";
import IfcBlock from "./Blocks/IfcBlock";
import LifehouseIfcBlock from "./Blocks/LifehouseIfcBlock";
import EsphIfcBlock from "./Blocks/EsphIfcBlock";
import NextPageBlock from "./Blocks/NextPageBlock";
import Form from "./Form/Form";
import JmphIfcBlock from "./Blocks/JmphIfcBlock";

// cant find more elegant way for dynamic load, so used best answer from
// https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const components = {
    html_block: HtmlBlock,
    agree_btn_block: AgreeBtnBlock,
    next_btn_block: NextPageBlock,
    info_table_block: InfoTableBlock,
    ifc_block: IfcBlock,
    lifehouse_ifc_block: LifehouseIfcBlock,
    jmph_ifc_block: JmphIfcBlock,
    esph_ifc_block: EsphIfcBlock,
    form: Form,
}

const DocumentPageBlock = ({block, index}) => {
    console.log('[DocumentPageBlock]', index, block)
    const ItemComponent = components[block.type];
    const key = block.index;

    return <ItemComponent key={key} {...block} />
}

export default DocumentPageBlock;