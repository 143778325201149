import React from 'react';
import PropTypes from 'prop-types';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import is from 'is_js';

const Date2 = props => {
    const handleChange = (value) => {
        console.log('    [Date2]     handleChange', value);
        let strDate = null;
        if (value !== null) {
            strDate = moment(value).format('DD/MM/YYYY');
        }
        console.log('    [Date2]     handleChange', strDate);
        props.input.onChange(strDate);
    }

    const {input, itemCols, placeholder, ...rest} = props;
    let value;
    if (is.date(input.value)) {
        value = input.value;
    } else if (input.value === '') {
        value = null;
    } else {
        value = moment(input.value, 'DD/MM/YYYY').toDate()
    }
    const className = rest.className ? rest.className : "form-control form-control-sm datepicker";

    const extraProps = {};
    if (props.maxDateSubstract) {
        extraProps.maxDate = moment().subtract(props.maxDateSubstract.number, props.maxDateSubstract.interval).toDate();
    }
    if (props.minDateSubstract) {
        extraProps.minDate = moment().subtract(props.minDateSubstract.number, props.minDateSubstract.interval).toDate();
    }
    if (props.maxDateAdd) {
        extraProps.maxDate = moment().add(props.maxDateAdd.number, props.maxDateAdd.interval).toDate();
    }
    if (props.minDateAdd) {
        extraProps.minDate = moment().add(props.minDateAdd.number, props.minDateAdd.interval).toDate();
    }
    let clearIcon=null;
    if (props.clearIcon) {
        clearIcon = <i className="icon-close"></i>
    }

    return (
        <DatePicker
            {...extraProps}
            format="d/MM/y"
            showLeadingZeros={true}
            value = {value}
            className={className}
            onChange={handleChange}
            calendarIcon={null}
            clearIcon={clearIcon}
            disableCalendar={props.disableCalendar ? true : false}
        />
    );
}

export default Date2;