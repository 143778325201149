import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {format, parse} from "date-fns";

import logo from '../../assets/images/logo.png';
import Loading from "../../components/Loading";
import {handleLogin} from "../../utils/auth";
import DatePicker from "../../components/Document/Form/Custom/Date4";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import Theme_WelcomeLogo from "../../components/Theme/Theme_WelcomeLogo";

const LoginPage = () => {
    const url = useLocation().pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let fixedUrl = (url.substr(0,1) === '/') ? url.substr(1) : url;
    fixedUrl = fixedUrl.split('/')[0];

    console.log('[LoginPage] url ', fixedUrl);

    const [dateOfBirth, setDateOfBirh] = useState(process.env.REACT_APP_DEFAULT_DOB);
    const [surname, setSurname] = useState(process.env.REACT_APP_DEFAULT_SURNAME);

    const [loginLoading, setLoginLoading] = useState(false);
    const [error, setError] = useState(false);

    const onLogin = () => navigate(`/${fixedUrl}/tasks/`);
    const onError = () => {
        setError(true);
        setLoginLoading(false);
    }
    const doLogin = (event) => {
        event.preventDefault();
        if (surname && dateOfBirth) {
            setLoginLoading(true);
            // console.log(dateOfBirth);
            handleLogin(fixedUrl, dateOfBirth, surname, dispatch, onLogin, onError);
        } else {
            setError(true);
        }
    }

    const renderForm = () => {
        return (
            <form className="signin-form" action="#" onSubmit={doLogin}>
                <h3 className="h3">Enter your date of birth and surname to log in</h3>
                <div className={`form-group${error? ' form-error' : ''}`}>
                    <label htmlFor="date">Date of Birth</label>
                    <div className="input-wrap">
                    <DatePicker
                        dateChange={setDateOfBirh}
                        defaultDate={dateOfBirth}
                        className="date4-select sm"
                    />
                    </div>
                </div>
                <div className={`form-group${error? ' form-error' : ''}`} style={{clear: 'both', paddingTop: '20px'}}>
                    <label htmlFor="surname">Surname</label>
                    <div className="input-wrap">
                        <input id="surname" className="form-control form-control-lg" type="text" placeholder="Enter your surname" onChange={event => setSurname(event.target.value)} value={surname}/>
                    </div>
                </div>
                <div className="btn-holdre">
                    <button onClick={doLogin} type="button" className="btn btn-primary btn-square btn-lg d-block w-100">Log In
                    </button>
                </div>
            </form>
        );
    }

    return (
        <div id="wrapper">
            <main id="main" className="p-0">
                <section className="signin-section login-block">
                    <div className="signin-block">
                        <div className="holder">
                            <Theme_WelcomeLogo />
                            {loginLoading ? <Loading /> : renderForm()}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default LoginPage;