import React from "react";
import {useSelector} from "react-redux";

const Theme_WelcomeImage = () => {
    const {loadedThemeId, theme} = useSelector(state => state.theme);

    if (loadedThemeId) {
        return (
            <div className="img-holder welcome-image">
                <span className="img"
                      style={{backgroundImage: "url(" + theme.cdn_path + theme.file_welcomePageImage + ")"}}/>
            </div>
        );
    }
    return null;
}

export default Theme_WelcomeImage;