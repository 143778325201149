import * as actionTypes from './actionTypes';
import axios from 'axios';
import { push } from '@lagunovsky/redux-react-router'

export const init = (token) => {
    return (dispatch, getState) => {
        console.log('[Impersonate.init] token=', token);

        axios.get(`/api/patient/impersonate/${token}`)
            .then((response) => {
                if (response.data.impersonate) {
                    dispatch({type: actionTypes.SET_IMPERSONATED_ADMISSION, admission: response.data.admission, token: token, manager: response.data.manager});
                } else {
                    dispatch({type: actionTypes.SET_IMPERSONATED_ERROR, error: response.data.error});
                    console.log('impersonate error!', response);
                }
            })
            .catch((error) => {
                dispatch({type: actionTypes.SET_IMPERSONATED_ERROR, error: 'Unknown error'});
                console.log('impersonate error!', error.response);
            });
    }
};