import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createRootReducer from './createRootReducer'
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    return createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            composeWithDevTools(
                applyMiddleware(
                    createRouterMiddleware(history), // for dispatching history actions
                    thunk
                ),
            )
        ),
    );
}