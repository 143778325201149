import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import axios from 'axios'

import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import {useAuth} from "../../utils/auth";
import Theme_Footer from "../../components/Theme/Theme_Footer";
import Loading from "../../components/Loading";
import * as actionTypes from "../../store/actions/actionTypes";

const ExternalPaymentDone = () => {
    const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuth();
    const {loadedThemeId} = useSelector(state => state.theme)

    // this request is used to load theme
    const fetchData = async () =>  {
        axios.get(`/api/patient/admission/tasks`, getAxiosAuthConfig())
            .then((response) => {
                // do nothing, theme applied in intercepor
            })
    };

    useEffect(() => {
        fetchData()
    }, []);

    if (!loadedThemeId) {
        return <Loading />
    }

    return (
        <div id="wrapper">
            <header className="header sticky-header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>

            <main id="main" className="pt-4 pt-md-5 pb-0 pb-md-6">
                <div className="container-simple">
                    <section className="tasks-block payment-completed">
                        <div className="head-block head-simple">
                            <h2>Payment Successful</h2>
                        </div>
                        <p>Your payment has been received and a receipt has been sent to your email</p>
                        <Link to={`/${getPatientPortalUrlPrefix()}/tasks`}
                              className="btn btn-primary btn-lg btn-square">Return home</Link>
                    </section>
                </div>
            </main>

            <Theme_Footer/>
        </div>
    );
}

export default ExternalPaymentDone;