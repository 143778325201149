import React from 'react';

const ReduxTextarea = ({labelCols, itemCols, maxRows, input, maxLineLength, ...otherProps}) => {
    // console.log('ReduxTextarea', maxLineLength);

    const handleChange = (event) => {
        const value = event.target.value;
        // console.log('    [ReduxTextarea]     handleChange', value);

        const linesCount = value.split(/\r\n|\r|\n/).length;

        const isLineLimit = maxRows && (linesCount > maxRows);

        let _maxLength = 0;
        value.split(/\r\n|\r|\n/).forEach(line => {
            if (line.length > _maxLength) {
                _maxLength = line.length;
            }
        });
        const isColsLimit = maxLineLength && (maxLineLength < _maxLength);
        if (!isLineLimit && !isColsLimit) {
            input.onChange(value);
        } else {
            console.log('Limit is reached!', isLineLimit, isColsLimit);
        }
    };

    delete otherProps.setError;
    return (
        <textarea
            {...input}
            {...otherProps}
            onChange={handleChange}
        />
    );
}

export default ReduxTextarea;