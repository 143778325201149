import React from "react";
import {useAuth} from "../../utils/auth";
import Tasks_StripePayment_Receipt from "./Tasks_StripePayment_Receipt";

const Tasks_ProcessingBlock = ({tasks}) => {
    const renderTask = task => {
        if (task.title === 'Excess Payment') {
            return <Tasks_StripePayment_Receipt task={task} />;
        }
        return <li key={task.hash}>
            <a href='#' className="tasks-item cursor-default">
                {task.title}
                <span className="icon icon-arrow-right"/>
            </a>
        </li>
        ;
    }


    if (tasks.length) {
        return (<section className="tasks-block">
            <div className="head-block head-simple">
                <h2>Processing Tasks</h2>
                <span className="counter">{tasks.length}</span>
            </div>

            <ul className="tasks list-unstyled">
                {tasks.map(renderTask)}
            </ul>
            {/*<span className="tasks-text">You have no completed tasks</span>*/}
        </section>
        );
    }
    return null;
}

export default Tasks_ProcessingBlock;