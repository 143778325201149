import React, {useState, useEffect} from 'react';
import {change, Field} from 'redux-form';
import PropTypes from 'prop-types';
import {isArray, isObject} from "underscore";
import {useDispatch, useSelector} from "react-redux";

import * as ComplexPropTypes from '../../complexPropTypes';
import {optionId} from "../../../../utils/utils";


const Radio = ({itemCols, options, name, disabled=false, unsetButton=false}) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const form = useSelector(state => state.form);
    const dispatch = useDispatch();

    const getValue = () => {
        if (form && form.wizard && form.wizard.values && form.wizard.values[name]) {
            return form.wizard.values[name]
        }
        return false;
    }

    const reset = () => {
        dispatch(change('wizard', name, null));
    }

    const renderUnsetButton = () => {
        if (unsetButton && getValue()) {
            return <a onClick={reset} className="btn btn-primary btn-rounded mw-100">{unsetButton}</a>
        }
        return null;
    }

    const handleClick = (event) => {
        if (selectedValue === event.target.value) {
            // unselect radio-button in redux-form
            reset();
            setSelectedValue(null);
        } else {
            setSelectedValue(event.target.value);
        }
    }
    useEffect(() => {
        if (form.wizard.values && form.wizard.values[name]) {
            setSelectedValue(form.wizard.values[name]);
        }

        if (isObject(form.wizard.values[name])) {
            console.error('ACA-464 - checkboxes to radio button conversion bug, trying to patch it', name, form.wizard.values[name])
            reset();
        }
    }, []);

    return (
        <div className={itemCols}>
            <ul className="check-list list-unstyled d-flex flex-wrap flex-row">
                {options.map((option, index) => (
                    <li key={index}>
                        <div className="custom-control custom-radio">
                            <Field component="input" type="radio" name={name} className="custom-control-input" id={`radio_${name}_${optionId(option)}`} value={option.value.toString()} disabled={disabled} onClick={handleClick}/>
                            <label className="custom-control-label p-0 m-0" htmlFor={`radio_${name}_${optionId(option)}`}>{option.label}</label>
                        </div>
                    </li>
                ))}
                {renderUnsetButton()}
            </ul>
        </div>
    );
}

Radio.propTypes = {
    itemCols: PropTypes.string.isRequired,
    options: ComplexPropTypes.optionsArray.isRequired,
    name: PropTypes.string.isRequired
};

export default Radio;