import React from 'react';
import {Field} from 'redux-form';
import {optionId} from "../../../../utils/utils";

const renderField = ({input, label, meta, options}) => {
    return (
        <div className={`row no-gutters ${meta.touched && meta.error && "form-error"}`} id={input.name}>
            <div className="col-12 col-lg-4">
                <label htmlFor={input.name} className="m-0">{label}</label>
            </div>
            <div className="col-12 col-lg-8">
                <ul className="check-list list-unstyled d-flex flex-wrap flex-row">
                    {options.map((option, index) => (
                        <li key={option.value}>
                            <div className="custom-control custom-radio">
                                <input  {...input} type="radio" name={input.name} className="custom-control-input" id={`radio_${input.name}_${optionId(option)}`} value={option.value}/>
                                <label className="custom-control-label p-0 m-0" htmlFor={`radio_${input.name}_${optionId(option)}`}>{option.label}</label>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const Radio = props => <Field {...props} component={renderField}/>;

export default Radio;