import React from "react";
import PubSub from "pubsub-js";
import * as pubSubMessages from "../../store/pubSubMessages";

const DevCard_PubSub = () => {
    const onClick = () => {
        PubSub.publish(pubSubMessages.VALIDATION_ERROR_SET, 'testCF10d');
    }
    const onClick2 = () => {
        PubSub.publish(pubSubMessages.VALIDATION_ERROR_CLEAR, 'testCF10d');
    }


    return (
        <div className="card mb-3" id="card-1">
            <div className="card-header row">
                <div className="col-12 col-lg-9"><h2 className="mb-2">Dev card</h2></div>
            </div>
            <div className="card-body">
                <div className="row no-gutters  ">
                    <div className="col-12">
                        <input type="button" value="Set error" onClick={onClick}/>
                        <input type="button" value="Clear error" onClick={onClick2}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DevCard_PubSub;