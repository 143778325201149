import React, {Fragment} from "react";
import {Field} from "redux-form";
import CustomList_Delete from "./CustomList_Delete";

const CustomList_3cells = ({rowKey, config, last=false}) => {

    const columnName = index => `${config.namePrefix}${config.columns[index].namePrefix}${rowKey}`;
    const columnLabel = index => config.columns[index].label;
    const columnPlaceholder = index => config.columns[index].placeholder;

    const renderField = index => <Field id={columnName(index)}
                                        component="input"
                                        type="text"
                                        name={columnName(index)}
                                        className="form-control form-control-sm"
                                        placeholder={columnPlaceholder(index)} />
    const renderLabel = index => <label htmlFor={columnName(index)} className="m-lg-0">{columnLabel(index)}</label>

    return (
        <Fragment key={rowKey}>
            <div className={`col-12 col-lg-${config.rowTemplate_cols[0]}`}>
                {renderLabel(0)}
            </div>
            <div className={`col-12 col-lg-${config.rowTemplate_cols[1]} ${last?'':'custom-list-block'}`}>
                <div className="row no-gutters">
                    <div className={`col-12 col-lg-${config.rowTemplate_cols[2]}`}>
                        {renderField(0)}
                    </div>
                    <div className={`col-12 col-lg-${config.rowTemplate_cols[3]} border-left`}>
                        <div className="row no-gutters">
                            <div className={`col-12 col-lg-${config.rowTemplate_cols[4]}`}>
                                {renderLabel(1)}
                            </div>
                            <div className={`col-12 col-lg-${config.rowTemplate_cols[5]}`}>
                                {renderField(1)}
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-${config.rowTemplate_cols[6]} border-left`}>
                        <div className="row no-gutters">
                            <div className={`col-12 col-lg-${config.rowTemplate_cols[7]}`}>
                                {renderLabel(2)}
                            </div>
                            <div className={`col-11 col-lg-${config.rowTemplate_cols[8]}`}>
                                {renderField(2)}
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-${config.rowTemplate_cols[9]} border-left`}>
                        <div className="row no-gutters">
                            <div className={`col-12 col-lg-${config.rowTemplate_cols[10]}`}>
                                {renderLabel(3)}
                            </div>
                            <div className={`col-11 col-lg-${config.rowTemplate_cols[11]}`}>
                                {renderField(3)}
                            </div>
                            <div className={`col-1 col-lg-${config.rowTemplate_cols[12]} text-center`}>
                                <CustomList_Delete config={config} rowKey={rowKey} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CustomList_3cells;