import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

const EsphIfcBlock = ({key, template}) => {
    const documentData = useSelector(state => state.main.currentDocument.documentData);

    const nonZeroAmount = (amount) => {
        if (amount === '0.00') {
            return '-';
        } else {
            return `$${amount}`
        }
    }
    const itemDesc = row => {
        console.log('item desc', row.ifc_itemType.substr(0,13), row);
        if (row.ifc_itemType.substr(0,13) === 'Accommodation') {
            return row.ifc_itemDesc;
        } else {
            return row.ifc_itemCode;
        }

    }

    const renderIfcRow = (row, index) => {
        if ((row.ifc_itemType === 'Theatre Fees') ||
            (row.ifc_rate !== '0.00') || (row.ifc_total !== '0.00') || (row.ifc_fundRebate !== '0.00') || (row.ifc_patientCost !== '0.00')
        ) {
            return (
                <div className="row w-100 no-gutters items-descr" key={index}>
                    <div className="col-12 col-lg-2">
                        <strong className="label m-lg-0">{row.ifc_itemCode}</strong>
                    </div>
                    <div className="col-12 col-lg-4">
                        <strong className="label m-lg-0">{row.ifc_itemDesc}</strong>
                    </div>
                    <div className="col-12 col-lg-6 pl-lg-2 items-list  items-list4">
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Hospital Fee</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_rate)}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Fund Rebate</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_fundRebate)}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Tax</span>
                            <span className="label ml-auto">{row.ifc_GST}</span>
                        </div>
                        <div className="item d-flex">
                            <span className="label text-default text-left d-lg-none">Patient Fee</span>
                            <span className="label ml-auto">{nonZeroAmount(row.ifc_patientCost)}</span>
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    return (
        <div className="card mb-2 mb-lg-4" key={key}>
            <div className="card-header">
                <h3 className="mb-0">Estimated</h3>
            </div>
            <div className="card-body body-small">
                <div className="row no-gutters pb-0 pb-lg-4">
                    <div className="col-12">
                        <div className="row w-100 no-gutters d-none d-lg-flex items-names mt-2 mb-3">
                            <div className="col-2">
                                <span className="label m-lg-0">CMBS Code</span>
                            </div>
                            <div className="col-4">
                                <span className="label m-lg-0">Description</span>
                            </div>
                            <div className="col-6 pl-2 items-list items-list4">
                                <div className="item d-flex">
                                    <span className="label ml-auto">Hospital Fee</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Fund Rebate</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Tax</span>
                                </div>
                                <div className="item d-flex">
                                    <span className="label ml-auto">Patient Fee</span>
                                </div>
                            </div>
                        </div>

                        {documentData.adm2_ifc___group.map(renderIfcRow)}

                    </div>

                </div>
                <div className="row no-gutters pt-3 pb-3 pb-lg-2">
                    <div className="col-12">
                        <div className="box">
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Fund Excess</span>
                                </div>
                                <div className="d-flex value">
                                    <span className="label m-lg-0">{documentData.adm2_patientExcess_str}</span>
                                </div>
                            </div>
                            {/*<div className="row no-gutters justify-content-end">*/}
                            {/*    <div className="d-flex">*/}
                            {/*        <span className="label text-default m-lg-0">GST</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="d-flex value">*/}
                            {/*        <span className="label m-lg-0">${documentData.adm2_ifc_total_GST}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="row no-gutters justify-content-end">
                                <div className="d-flex">
                                    <span className="label text-default m-lg-0">Total Estimated Cost</span>
                                </div>
                                <div className="d-flex value">
                                    <strong className="label m-lg-0">${documentData.adm2_ifc_total_estimatedCost}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EsphIfcBlock;