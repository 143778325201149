import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth} from "../../../utils/auth";
import * as wizardActionCreators from "../store/actions/wizard";

const ProgressPanelReviewItem = ({onNextStep}) => {
    const {currentStep, documentHash, allStepsEnabled} = useSelector(state => state.progressPanel);
    const {getPatientPortalUrlPrefix} = useAuth();
    const dispatch = useDispatch();

    // const goToReview = () => dispatch(wizardActionCreators.wizardSave(`/${getPatientPortalUrlPrefix()}/review/${documentHash}`));
    const goToReview = () => onNextStep();

    if (allStepsEnabled) {
        if (currentStep === -1) {
            return (
                <li className="active">
                    Review
                </li>
            );
        } else {
            return (
                <li>
                    <a onClick={goToReview} className="cursor-pointer">Review</a>
                </li>
            );
        }
    } else {
        return (
            <li className="progress-bar-inactive">Review</li>
        );
    }
};

export default ProgressPanelReviewItem;