import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../Fields/Tooltip";

const cardHeader = ({tooltip='', title, subTitle, titleClass}) => {
    const _titleClass = titleClass ? titleClass : (tooltip ? "col-10" : "col-12 col-lg-9");

    return (
            <div className="card-header row">
                <div className={_titleClass}>
                    <h2 className="mb-2">{title}</h2>
                    {subTitle && <p dangerouslySetInnerHTML={{__html: subTitle}}/>}
                </div>
                {tooltip && <div className="col-2 text-right"><Tooltip text={tooltip} extraCss="align-right"/></div>}
            </div>
        );
};

export default cardHeader;