import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {throttle} from "underscore";

import {getReviewValues} from "../Document_v2/utils/reviewValues";

const ReviewValues = ({setSubTitle}) => {
    const {admissionType, values} = useSelector(state => state.review);

    const reviewValues = getReviewValues(admissionType, values);
    // console.log('[ReviewValues]', JSON.stringify(reviewValues, null, 3));

    const updateTitle = () => {
        let founded = '';
        reviewValues.forEach((card, index) => {
            var elt = document.getElementById('card-'+index);
            if (!elt) {
                return false;
            }
            var rect = elt.getBoundingClientRect();

            if (!founded || (rect.top < 90)) {
                founded = card.cardTitle;
            }
        });

        if (founded) {
            setSubTitle(founded);
            // console.log('updateTitle !', founded);
        }
    };
    const throttledUpdateTitle = throttle(updateTitle, 200);

    useEffect(() => {
        window.addEventListener('scroll', throttledUpdateTitle)
        updateTitle();
        return () => {
            window.removeEventListener('scroll', throttledUpdateTitle)
        }
    }, []);

    const renderCard = (card, cardIndex) => (
        <div className="card mb-3" key={cardIndex} id={`card-${cardIndex}`}>
            <div className="card-header row">
                <div className="col-12 col-lg-9">
                    <h2 className="m-md-0">{card.cardTitle}</h2>
                </div>
            </div>
            <div className="card-body">
                {card.reviewFields.map((field, fieldIndex) => (
                    <div className="row no-gutters " key={fieldIndex}>
                        <div className="col-12 col-lg-5">
                            <span className="label m-lg-0" dangerouslySetInnerHTML={{__html: field.label}} />
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="preview-value">
                                {field.value}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return reviewValues.map(renderCard);
};
export default ReviewValues;