import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import ReduxTextarea from "./ReduxComponent/ReduxTextarea";

const Textarea = props => {
    console.log('Textarea ', props);

    return (
        <div className={props.itemCols}>
            <Field component={ReduxTextarea}
                   className="form-control form-control-sm"
                   {...props}
                   id={props.name}
            />
        </div>
    );
};

Textarea.propTypes = {
    itemCols: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired
};

export default Textarea;