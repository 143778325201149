import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {change} from "redux-form";

const CustomList_Delete = ({rowKey, config}) => {
    const dispatch = useDispatch();
    const wizardForm = useSelector(state => state.form.wizard);
    const rowsCount = () => wizardForm.values[`${config.namePrefix}_count`];

    const deleteRow = (e) => {
        e.preventDefault();
        for (let i = rowKey; i < rowsCount(); i++) {
            config.columns.map((column, columnIndex) => {
                dispatch(change('wizard', `${config.namePrefix}${column.namePrefix}${i}`, wizardForm.values[`${config.namePrefix}${column.namePrefix}${i + 1}`] || ''))
            });
            if (rowsCount() > 1) {
                dispatch(change('wizard', `${config.namePrefix}_count`, rowsCount() - 1));
            }
        }
        if ((rowsCount() === 1) && config.zeroTarget) {
            dispatch(change('wizard', config.zeroTarget.name, config.zeroTarget.value));
        }
    }
    return <a onClick={deleteRow} className="upload-link cursor-pointer"><span className="icon icon-close" /></a>
}

export default CustomList_Delete;