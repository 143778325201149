import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {reduxForm} from "redux-form";

import DocumentPageBlock from "./DocumentPageBlock";
import * as actionCreators from '../../store/actions/main';

const handleSubmitFormFail = (error, dispatch, submitError, props) => {
    const firstErrorElementId = Object.keys(error)[0];

    const element = document.getElementById(firstErrorElementId);
    if (element) {
        console.log('[Document] handleSubmitFormFail() scrolling to ', firstErrorElementId);
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - (window.innerHeight / 2);
        window.scrollTo(0, middle);
    } else {
        console.error('[Document] handleSubmitFormFail() cant find element #', firstErrorElementId);
    }
}

const Document = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const document = useSelector(state => state.main.currentDocument);

    const { documentPage, handleSubmit } = props;
    const pagesCount = document.template.pages.length;
    console.log('[Document] ---> ', props);

    const renderBlocks = () => document.template.pages[documentPage-1].blocks.map((block, index) => <DocumentPageBlock key={index} block={block} index={index}/>);

    const renderTopPager = () => {
        if (pagesCount > 1) {
            return <span className="steps">{documentPage} of {pagesCount}</span>
        }
        return null;
    }

    const onSubmit = () => {
        console.log('[Form] Submitted');
        dispatch(actionCreators.processDocument());
    }

    return (
        <main id="main" className="pt-0 pt-md-5 pb-0 pb-md-6 main-grow">
            <section className="container-small">
                <div className="head-block">
                    <h2>
                        <a onClick={() => navigate(-1)} className="back-link"><i className="icon icon-arrow-left" /></a>
                        <span className="ml-2">{document.title}</span>
                    </h2>
                    {renderTopPager()}
                </div>
                <form className="review-form" onSubmit={handleSubmit(onSubmit)}>
                {renderBlocks()}
                </form>
            </section>
        </main>
    );
}

export default reduxForm ({
    form: 'document',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmitFail: handleSubmitFormFail,
}) (Document);