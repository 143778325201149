export const LOADING_START = 'LOADING_START';
export const LOADING_DONE  = 'LOADING_DONE';

export const SET_ADMISSION = 'SET_ADMISSION';
export const SET_ADMISSION_MESSAGE = 'SET_ADMISSION_MESSAGE';
export const SET_THEME_AND_HOSPITAL_CONFIG = 'SET_THEME_AND_HOSPITAL_CONFIG';
export const SET_TASKS = 'SET_TASKS';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const UPDATE_CURRENT_DOCUMENT_DATA = 'UPDATE_CURRENT_DOCUMENT_DATA';
export const UNSET_DOCUMENT = 'UNSET_DOCUMENT';
export const SET_DOCUMENT_LOADING_ERROR = 'SET_DOCUMENT_LOADING_ERROR';

export const DOCUMENT_PROCESSING_START = 'DOCUMENT_PROCESSING_START';
export const DOCUMENT_PROCESSING_DONE = 'DOCUMENT_PROCESSING_DONE';

export const PROGRESS_PANEL_INIT = 'PROGRESS_PANEL_INIT';
export const PROGRESS_PANEL_CHANGE_STEP = 'PROGRESS_PANEL_CHANGE_STEP';
export const PROGRESS_PANEL_ALL_STEPS_ENABLED = 'PROGRESS_PANEL_ALL_REQUIRED_FIELDS_FILLED';


export const REVIEW_ADD_ERROR = 'REVIEW_ADD_ERROR';
export const REVIEW_REMOVE_ERROR = 'REVIEW_REMOVE_ERROR';
export const REVIEW_INIT = 'REVIEW_INIT';
export const REVIEW_SIG_PAD_DRAW = 'REVIEW_SIG_PAD_DRAW';
export const REVIEW_SIG_PAD_RESET = 'REVIEW_SIG_PAD_RESET';
export const REVIEW_RESET = 'REVIEW_RESET';

export const CUSTOM_VALIDATION_SET_ERROR = 'CUSTOM_VALIDATION_SET_ERROR';
export const CUSTOM_VALIDATION_SET_COMPLEX_ERROR = 'CUSTOM_VALIDATION_SET_COMPLEX_ERROR';

export const SET_IMPERSONATED_ADMISSION = 'SET_IMPERSONATED_ADMISSION';
export const SET_IMPERSONATED_ERROR = 'SET_IMPERSONATED_ERROR';

export const SET_INTRO_DONE = 'SET_INTRO_DONE';