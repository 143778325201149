import React from 'react';
import DatePicker from'../Custom/Date4';
import moment from 'moment';
import is from 'is_js';

const Date4 = props => {
    let defaultDate = null;
    if ((props.input.value !== '') && (props.input.value!== null) && (props.input.value !== 'Invalid date')) {
        const momentDate = moment(props.input.value, 'DD/MM/YYYY');
        defaultDate = momentDate.isValid() ? momentDate.format() : null;
    }
    console.log('[Date4] init', props.input.value, defaultDate);

    const handleChange = (value) => {
        console.log('    [Date4]     handleChange', value);
        let strDate = null;
        if (value !== null) {
            const momentDate = moment(value, 'YYYY-MM-DD');
            strDate = momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : null;
        }
        console.log('    [Date4]     handleChange', strDate);
        props.input.onChange(strDate);
    }

    return (
        <DatePicker
            dateChange={handleChange}
            defaultDate={defaultDate}
            className="date4-select"
        />
    )
}

export default Date4;