import React, {PureComponent, Fragment} from 'react';
import jcf from 'jcf/js/jcf';
import PubSub from 'pubsub-js';
import ReactDOM from 'react-dom'

import * as pubSubMessages from '../../store/pubSubMessages';
import FormLabel from "../Fields/Label";
import FormYesNo from "../Fields/YesNo";
import FormDate from "../Fields/DatePicker";
import FormDate2 from "../Fields/Date2";
import FormDate3 from "../Fields/Date3";
import FormDate4 from "../Fields/Date4";
import FormSelect from "../Fields/Select";
import FormSelectAutocomplete from "../Fields/SelectAutocomplete";
import FormSelectAsync from "../Fields/SelectAsync";
import FormInput from "../Fields/Input";
import FormTextarea from "../Fields/Textarea";
import FormRadio from "../Fields/Radio";
import FormSuburbStatePostcode from "../Fields/Complex/SuburbStatePostcode";
import FormDoubleInput from "../Fields/Complex/DoubleInput";
import FormDateInput from "../Fields/Complex/DateInput";
import FormDateMMYY from "../Fields/DateMMYY";
import FormCheckboxes from "../Fields/Checkboxes";
import FormDVACardColour from '../Fields/Complex/DVACardColour';
import FormMedicationDetails from '../Fields/Complex/MedicationDetails';
import FormMedicationList from '../Fields/Complex/MedicationList';
import FormMedicationAdd from '../Fields/Complex/MedicationAdd';
import FormBodyMassIndex from '../Fields/Complex/BodyMassIndex';
import FormGooglePlacesAddress from '../Fields/GooglePlacesAddress';
// WIP - GooglePlacesAddress2 component is not ready to use
// https://matthew-maks.atlassian.net/browse/ACA-18
// import FormGooglePlacesAddress2 from '../Fields/GooglePlacesAddress2';
import FormCustomList from '../Fields/CustomList/CustomList';
import FormCustomList_Add from '../Fields/CustomList/CustomList_Add'

// DEV
import FormAca107Input1 from '../Fields/Dev/Aca107Input1';
import FormAca107Input2 from '../Fields/Dev/Aca107Input2';

import {requiredFieldsForItem} from "../../utils/parseRequiredFields";
import {autoOptions} from '../../utils/autoOptions';

// cant find more elegant way for dynamic load, so used best answer from
// https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const components = {
    YesNo: FormYesNo,
    SuburbStatePostcode: FormSuburbStatePostcode,
    DoubleInput: FormDoubleInput,
    DateInput: FormDateInput,
    Select: FormSelect,
    SelectAutocomplete: FormSelectAutocomplete,
    SelectAsync: FormSelectAsync,
    Radio: FormRadio,
    Checkboxes: FormCheckboxes,
    Input: FormInput,
    Textarea: FormTextarea,
    Date: FormDate,
    Date2: FormDate2,
    Date3: FormDate3,
    Date4: FormDate4,
    DateMMYY: FormDateMMYY,
    DVACardColour: FormDVACardColour,
    MedicationDetails: FormMedicationDetails,
    MedicationList: FormMedicationList,
    MedicationAdd: FormMedicationAdd,
    BodyMassIndex: FormBodyMassIndex,
    Label: FormLabel,
    CustomList: FormCustomList,
    CustomList_Add: FormCustomList_Add,
    GooglePlacesAddress: FormGooglePlacesAddress,
    // WIP - GooglePlacesAddress2 component is not ready to use
    // https://matthew-maks.atlassian.net/browse/ACA-18
    // GooglePlacesAddress2: FormGooglePlacesAddress2,

//  DEV
    Aca107Input1: FormAca107Input1,
    Aca107Input2: FormAca107Input2,
}

class CardRow extends PureComponent {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
    }

    static whyDidYouRender = true;

    requiredItems = [];

    state = {
        visibleIfSelectedYes: false,
        // https://matthew-maks.atlassian.net/browse/ACA-19
        // Patch for RequiredIfNotEmpty
        requiredIfNotEmpty: false,
        requiredIfEmpty: false,
        error: false,
        errorItems: [],
    };

    componentDidMount(){
        if (this.props.row.visibleIfSelectedYes) {
            this.tokenYesSelected = PubSub.subscribe(pubSubMessages.YES_SELECTED, this.pubSubSubscriber);
            this.tokenNoSelected = PubSub.subscribe(pubSubMessages.NO_SELECTED, this.pubSubSubscriber);
        }
        if (this.props.formInitialValues && (this.props.formInitialValues[this.props.row.visibleIfSelectedYes] === 'yes') && !this.state.visibleIfSelectedYes) {
            this.setState({visibleIfSelectedYes: true})
        }
    }

    componentWillUnmount(){
        if (this.tokenYesSelected) {
            PubSub.unsubscribe(this.tokenYesSelected);
            PubSub.unsubscribe(this.tokenNoSelected);
        }
        if (this.tokenValidationErrorSet) {
            PubSub.unsubscribe(this.tokenValidationErrorSet);
            PubSub.unsubscribe(this.tokenValidationErrorClear);
            PubSub.unsubscribe(this.tokenValidationErrorScroll);
        }
    }
    // not sure why, but its not working
    // scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);
    // scrollToMyRef = () => ReactDOM.findDOMNode(this.myRef.current).scrollIntoView({ behavior: 'smooth' });
    scrollToMyRef = () => {
        // console.log('scroll to Y ', this.myRef.current.offsetTop+150);
        // this.myRef && this.myRef.current && window.scrollTo(0, this.myRef.current.offsetTop+150);
        if (this.myRef && this.myRef.current) {
            // const elementRect = ReactDOM.findDOMNode(this.myRef.selector).getBoundingClientRect()
            const elementRect = this.myRef.current.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2);
            window.scrollTo(0, middle);
        }
    }

    pubSubSubscriber = (msg, data) => {
        // https://matthew-maks.atlassian.net/browse/ACA-19
        // Patch for RequiredIfNotEmpty
        if (this.state.error) {
            if ((msg === pubSubMessages.VALIDATION_ERROR_CLEAR) && (this.state.requiredIfNotEmpty || this.state.requiredIfEmpty)) {
                this.setState({error: false, errorItems: []});
            }
        }
        if ((msg === pubSubMessages.YES_SELECTED) && (this.props.row.visibleIfSelectedYes === data)) {
            this.setState({visibleIfSelectedYes: true})
        }
        if ((msg === pubSubMessages.NO_SELECTED) && (this.props.row.visibleIfSelectedYes === data)) {
            this.setState({visibleIfSelectedYes: false})
        }
        if ((msg === pubSubMessages.VALIDATION_ERROR_SET) && (this.requiredItems.indexOf(data) !== -1)) {
            this.setState({error: true, errorItems: [data, ...this.state.errorItems]});
        }
        if ((msg === pubSubMessages.VALIDATION_ERROR_CLEAR) && (this.requiredItems.indexOf(data) !== -1)) {
            // console.log('VALIDATION_ERROR_CLEAR', data, this.requiredItems);
            if (this.state.errorItems.indexOf(data) !== -1) {
                const errorItems = this.state.errorItems.filter(item => item !== data);
                this.setState({error: errorItems.length > 0, errorItems});
            } else {
                // console.log('Bug with VALIDATION_ERROR_CLEAR', data, this.state.errorItems);
            }
        }
        if ((msg === pubSubMessages.VALIDATION_ERROR_SCROLL) && (this.requiredItems.indexOf(data) !== -1)) {
            this.scrollToMyRef();
        }
    };

    renderItem = (item) => {
        // default values for labelCols / itemCols styles
        item.labelCols = item.labelCols || this.props.labelCols;
        item.itemCols = item.itemCols || this.props.itemCols;

        if(item.autoOptions) {
            item.options = autoOptions(item.autoOptions);
        }


        if(item.options) {
            // convert "short options format" (array of strings) to "complete" (array of value/label objects)
            if ((typeof item.options[0] === 'string')) {
                item.options = item.options.map((string) => ({value: string, label: string}));
            }

            // convert int values to string
            item.options = item.options.map(_i => ({..._i, value: _i.value.toString()}));
        }
        if (!item.type) {
            console.error('Wront item - ', item);
            return;
        }

        const ItemComponent = components[item.type];
        if (!ItemComponent) {
            console.error('Unknown type - ', item.type);
            return;
        }
        const key = item.name || item.namePrefix || item.name1 || this.props.index;

        if ((item.required === true) || item.requiredIfNotEmpty || item.customValidation || item.requiredIfEmpty) {
            requiredFieldsForItem(item).map(_i => this.requiredItems.push(_i))
            if (!this.tokenValidationErrorSet) {
                this.tokenValidationErrorSet = PubSub.subscribe(pubSubMessages.VALIDATION_ERROR_SET, this.pubSubSubscriber);
                this.tokenValidationErrorClear = PubSub.subscribe(pubSubMessages.VALIDATION_ERROR_CLEAR, this.pubSubSubscriber);
                this.tokenValidationErrorScroll = PubSub.subscribe(pubSubMessages.VALIDATION_ERROR_SCROLL, this.pubSubSubscriber);
            }
        }
        // https://matthew-maks.atlassian.net/browse/ACA-19
        // Patch for RequiredIfNotEmpty
        if (item.requiredIfNotEmpty) {
            this.setState({requiredIfNotEmpty: true})
        }
        if (item.requiredIfEmpty) {
            this.setState({requiredIfEmpty: true})
        }

        item.setError = (error) => this.setState({error});

        // Cant use redux-form Field here because some items have few inputs (like DoubleInput or SuburbStatePostcode)
        return (
            <Fragment key={key}>
                {(item.label || item.labelHtml) && <FormLabel {...item} htmlFor={item.name || ''}/>}
                {(item.type !== 'Label') && <ItemComponent {...item} error={this.state.error}/>}
            </Fragment>
        );
    };

    renderRow = (extraStyle = '') => {
        return (
            <div className={`row no-gutters ${extraStyle} ${this.state.error?'error':''}`}>
                <div ref={this.myRef} />
                {this.props.row.items.map(this.renderItem)}
            </div>
        );
    };

    render = () => {
        if (this.props.row.visibleIfSelectedYes) {
            return this.renderRow('bg-gray');
        } else {
            return this.renderRow(this.props.row.extraRowStyle || this.props.extraRowStyle || '');
        }
    }
};

// using mapStateToProps/connect approach would re-render all tree on every redux event (every single input change)
// so, switching to pubsub-js

// const mapStateToProps = state => (
//     {formValues: getFormValues('wizard')(state)}
// );

// export default connect(mapStateToProps)(CardRow);
export default CardRow;