import React from 'react';
import {useAuth} from "../../utils/auth";

const Tasks_StripePayment_Receipt = ({task}) => {
    const formRef = React.useRef(null);
    const {getAxiosAuthConfig, getPatientPortalUrlPrefix, getAuthorizationTokenInput} = useAuth();
    const viewReceipt = (event) => {
        formRef.current.submit();
        event.preventDefault();
    }

    return (
        <li key={task.hash}>
            <a href='#' onClick={viewReceipt} className="tasks-item">
                Excess Payment - Download receipt
                <span className="icon icon-arrow-right"/>
            </a>
            <div style={{display: 'none'}}>
                <form action={`${process.env.REACT_APP_API_PATH}/api/patient/stripe-payment/receipt/${task.session || task.hash}`} method="post"
                      ref={formRef}>
                    {getAuthorizationTokenInput()}
                </form>
            </div>
        </li>
    )
}

export default Tasks_StripePayment_Receipt;