import React from 'react';
import {connect, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useAuth} from "../../../../utils/auth";

const BackButton = ({ready, uuid, currentStep, steps}) => {
    const currentDocument = useSelector(state => state.main.currentDocument);
    const {getPatientPortalUrlPrefix} = useAuth();
    const navigate = useNavigate();

    if (ready && (currentStep > 1)) {
        return (
            <li>
                <a onClick={() => navigate(`/${getPatientPortalUrlPrefix()}/document/2/${currentDocument.hash}/${currentStep-1}`)} className="cursor-pointer"><i className="icon icon-long-arrow-right" />Back to {steps[currentStep-2]}</a>
            </li>
        );
    }
    return null;
};

const mapStateToProps = state => ({
    ready:          state.wizard.ready,
    currentStep:    state.wizard.currentStep,
    steps:          state.wizard.steps,
    uuid:           state.wizard.uuid,
});

export default connect(mapStateToProps)(BackButton);