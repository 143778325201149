import React, {Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";
import {useAuth} from "../../../utils/auth";

const scrollToTop = () => {
    const element = document.getElementById('document_header');
    if (element) {
        console.log('[Document] scrollToTop() scrolling to ', element);
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - (window.innerHeight / 2);
        window.scrollTo(0, middle);
    }
}

const NextPageBlock = ({key, next_btn_title, target_page}) => {
    const {getPatientPortalUrlPrefix} = useAuth();
    const navigate = useNavigate();
    const document = useSelector(state => state.main.currentDocument);

    const goBack = () => navigate(-1);
    const next = () => {
        navigate(`/${getPatientPortalUrlPrefix()}/document/1/${document.hash}/${target_page}`);
        scrollToTop();
    }

    return (
        <div className="card controls-block " key={key}>
            <div className="card-body">
                <div className="row no-gutters pt-3 pt-lg-4 pb-3">
                    <div className="col-12">
                        <ul
                            className="btn-navigation m-0 list-unstyled w-100 d-flex flex-column flex-nowrap flex-sm-row-reverse align-items-sm-center">
                            <li>
                                <button type="button" onClick={next} className="btn btn-primary btn-lg btn-square">{next_btn_title}</button>
                            </li>
                            {/*<li>*/}
                            {/*    <a onClick={goBack} className="back-link"><i className="icon icon-arrow-left" /> <span*/}
                            {/*        className="ml-2">Go back</span></a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NextPageBlock;