import React from 'react';
import {Field} from 'redux-form';

import {optionId} from "../../../../utils/utils";

const renderField = ({input, label, meta, options}) => {
    // console.log('input.value', input.value);
    return (
        <div className={`row no-gutters ${meta.touched && meta.error && "form-error"}`} id={input.name}>
            <div className="col-12 col-lg-4">
                <label htmlFor={input.name} className="m-0">{label}</label>
            </div>
            <div className="col-12 col-lg-8">
                <ul className="check-list list-unstyled d-flex flex-wrap flex-row">
                    {options.map((option, index) => (
                        <li key={index}>
                            <div className="custom-control custom-checkbox">
                                <input
                                       checked={input.value[option.value] ? true : false}
                                       onChange={(event) => {
                                           const newValue = {...input.value};
                                           newValue[option.value] = event.target.checked;

                                           // console.log('[Check] onChange', newValue);
                                           return input.onChange(newValue);
                                       }}

                                       type="checkbox"
                                       className="custom-control-input"
                                       id={`check_${input.name}_${optionId(option)}`}
                                       name={`${input.name}[${optionId(option)}]`}
                                />
                                <label className="custom-control-label p-0 m-0"
                                       htmlFor={`check_${input.name}_${optionId(option)}`}>
                                    {option.label}
                                </label>
                            </div>
                        </li>))
                    }
                </ul>
            </div>
        </div>
    );
};

const Check = props => <Field {...props} component={renderField}/>;

export default Check;