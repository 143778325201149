import React, {useState} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

const Aca107Input2 = ({itemCols, name, value, placeholder}) => {
    return (
        <div className={`${itemCols}`}>
            <div className="input-error2">
                Please check Medicare Number and Number beside name on card
            </div>
            <Field component="input"
                   type="text"
                   id={name}
                   name={name}
                   className="form-control form-control-sm"
                   value={value}
                   placeholder={placeholder}
                   autoComplete={Math.random().toString(36).substring(2, 15)}
            />
        </div>
    );
};

export default Aca107Input2;