import React, {useState} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

const Aca107Input1 = ({itemCols, name, value, placeholder}) => {
    const [visible, setVisible] = useState(false);
    return (
        <div className={`${itemCols}`}>
            <div className="input-error1" onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
                <a className={`tooltip-opener icon-help ${visible ? 'hover' : ''}`} >
        <span className="tooltip-drop">
            <span className="tooltip-wrap">
                <span className="text">Please check Medicare Number and Number beside name on card</span>
            </span>
        </span>
                </a>

            </div>
            <Field component="input"
                   type="text"
                   id={name}
                   name={name}
                   className="form-control form-control-sm"
                   value={value}
                   placeholder={placeholder}
                   autoComplete={Math.random().toString(36).substring(2, 15)}
            />
        </div>
    );
};

export default Aca107Input1;