import React from 'react';
import {Field} from 'redux-form';

const Text = props => (
    <div className="row no-gutters">
        <div className="col-12 col-lg-12">
            <div className="text-row" dangerouslySetInnerHTML={{__html: props.label}} />
        </div>
    </div>
);

export default Text;