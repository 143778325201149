const visibleIf = (name, value, reduxForm) => {
    // either form OR form values
    const values = reduxForm ? (reduxForm.values ? reduxForm.values : reduxForm) : [];
    if (values && values[name]) {
        if ((typeof value === 'string') || (value instanceof String)) {
            if ((typeof values[name] === 'string') || (values[name] instanceof String)) {
                if (values[name] === value) {
                    // console.log('string = YES');
                    return true;
                }
            }
            if ((typeof values[name] === 'object')) {
                if (values[name][value] === true) {
                    // console.log('string = YES');
                    return true;
                }
            }
        }
        if ((typeof value === 'object') && (value.constructor === Array)) {
            if (value.includes(values[name])) {
                // console.log('array = YES');
                return true;
            }
        }
    }
    return false;
}

export default visibleIf;