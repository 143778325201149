import React from 'react';
import {Field} from 'redux-form';
import Date2Redux from "./ReduxComponent/Date2";

const Date2 = (props) => {
    return (
        <div className={props.itemCols}>
            <Field component={Date2Redux} {...props} />
        </div>
    );
};

export default Date2;