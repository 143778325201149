import React, {useEffect} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import * as ComplexPropTypes from '../../complexPropTypes';
import {useSelector} from "react-redux";

const Select = ({name, itemCols, value, options, defaultEmptyOption, disabled = false, setError}) => {
    const error = useSelector(state => state.customValidationErrors.errors[name]);
    useEffect(() => {
        setError(!!error);
    }, [error]);
    const valueStr = value ? value.toString() : value;
    return (
        <div className={itemCols}>
            {error && <div className="input-error2">{error}</div>}
            <Field disabled={disabled} className="sm" name={name} value={valueStr} component="select">
                {defaultEmptyOption && <option value="">{defaultEmptyOption}</option>}
                {options.map(
                    (option, index) => (
                    <option key={index} value={option.value.toString()}>{option.label || option}</option>
                    )
                )}
            </Field>
        </div>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    itemCols: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: ComplexPropTypes.optionsArray.isRequired
};

export default Select;