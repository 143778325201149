import React from "react";
import {useSelector} from "react-redux";

import DevCard_PubSub from "./DevCard_PubSub";

const DevCard = () => {
    const betaTester = useSelector(state => state.app.betaTester);

    if (!betaTester) {
        return null;
    }

    return <DevCard_PubSub />
}

export default DevCard;