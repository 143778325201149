import React, {useEffect, useState} from 'react';
import { components } from 'react-select'
import AsyncSelect from 'react-select/async';

import * as selectAsyncActions from '../../../store/actions/selectAsync';
import {useDispatch} from "react-redux";
import loadingGif from "../../../../../assets/images/loader.gif";
import {Theme_ReactSelectAsync} from "../../../../Theme/Theme_ReactSelect";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="autocomplete-dropdown select-async-dropdown" />
        </components.DropdownIndicator>
    );
};
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <svg height="15" width="15" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                <path
                    d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
        </components.ClearIndicator>
    );
};
// const LoadingIndicator = (props) => {
//     return (
//         <components.LoadingIndicator {...props} />
//     );
// };

export const ReduxSelectAsync = props => {
    const dispatch = useDispatch();
    const [stateValue, setStateValue] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { input } = props;

    console.log('[ReduxSelectAsync] init', props);

    const dump = (provided, state) => {
        console.log('DUMP ', provided, state);
        return provided;
    };

    // const getValueOption = () => {
    //     if (typeof input.value === 'object') {
    //         return input.value;
    //     } else {
    //         const selection = options.filter(_i => _i.value === input.value);
    //         return selection.length ? selection[0] : null;
    //     }
    // }
    useEffect(() => {
        console.log('[ReduxSelectAsync] useEffect(..., [])')
    }, []);

    const loadOptions = (inputValue, callback) => {
        console.log('[ReduxSelectAsync] loadOptions', inputValue);
        if (inputValue.length === 0) {
            dispatch(selectAsyncActions.loadValue(input.value, (option) => {
                callback([option]);
                setStateValue(option);
                setIsLoading(false);
            }));
        }else if (inputValue.length < 3) {
            callback([]);
        } else {
            dispatch(selectAsyncActions.loadOptions(inputValue, callback));
        }
    }
    const noOptionsMessage = (input) => {
        if (input.inputValue.length === 0) {
            return "Type to search";
        } else if(input.inputValue.length < 3) {
            return "type 3 letters and then select";
        } else {
            return "No options";
        }
    }

    const handleChange = value => {
        setStateValue(value);
        input.onChange(value);
    }

    return (
        <AsyncSelect
            {...input}
            defaultOptions={true}
            value={stateValue}
            isClearable
            styles={Theme_ReactSelectAsync()}
            onChange={handleChange}
            onBlur={event => event.preventDefault()}
            cacheOptions
            loadOptions={loadOptions}
            className="select-autocomplete"
            components={{DropdownIndicator, ClearIndicator}} // LoadingIndicator
            noOptionsMessage={noOptionsMessage}
            isLoading={isLoading}
            isDisabled={isLoading}
            placeholder={props.placeholder}
        />
    )
}

export default ReduxSelectAsync;