import React from "react";
import { push } from '@lagunovsky/redux-react-router';
import {useDispatch} from "react-redux";

const IndexPage = () => {
    const dispatch = useDispatch();

    if (process.env.REACT_APP_DEFAULT_URL) {
        dispatch(push(process.env.REACT_APP_DEFAULT_URL));
    }
    return <br/>;
}

export default IndexPage;