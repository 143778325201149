import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import axios from "axios";

import Loading from "../../components/Loading";
import LoginPage from "./LoginPage";
import Theme_WelcomeImage from "../../components/Theme/Theme_WelcomeImage";
import Theme_WelcomeText from "../../components/Theme/Theme_WelcomeText";
import Theme_WelcomeLogo from "../../components/Theme/Theme_WelcomeLogo";

const WelcomePage = () => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [loginPage, setLoginPage] = useState(false);
    const url = useLocation().pathname;
    let fixedUrl = (url.substr(0,1) === '/') ? url.substr(1) : url;

    console.log(process.env.REACT_APP_API_PATH)
    const checkUser = () => {
        axios.post(`/api/patient/start/admission`, {url:fixedUrl.split('/')[0]})
            .then((response) => {
                if (response.data.admission && response.data.admission.name) {
                    setName(response.data.admission.name);
                    setLoading(false);
                } else {
                    // TODO: show error message
                }
            })
    }
    useEffect(() => {
        checkUser();
    }, []);

    if (loading) {
        return <Loading />
    }

    const goToLoginPage = event => {
        setLoginPage(true);
        event.preventDefault()
    }

    if (loginPage) {
        return <LoginPage />
    }

    return (
        <div id="wrapper">
            <main id="main" className="p-0">
                <section className="signin-section skew-section">
                    <div className="signin-block">
                        <div className="holder">
                            <Theme_WelcomeLogo onClick={goToLoginPage}/>
                            <div className="signin-content">
                                <Theme_WelcomeText name={name}/>
                                <div className="btn-holdre">
                                    <button type="button" onClick={goToLoginPage} className="btn btn-primary btn-square btn-lg d-block">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Theme_WelcomeImage/>
                </section>
            </main>
        </div>
    );
}

export default WelcomePage;