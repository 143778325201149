import React from 'react';
import CardHeader from "./CardHeader";
import CardRow from "./CardRow";
import VisibleIf from "../VisibleIf";

const card = (props) => {
    // console.log('Card rendering ', props.card.title);
    const labelCols = props.card.labelCols || props.labelCols;
    const itemCols = props.card.itemCols || props.itemCols;

    const renderRow = (row, index) => {
        const jsx = (
            <CardRow
                row={row}
                index={index}
                key={index}
                labelCols={labelCols}
                itemCols={itemCols}
                formInitialValues={props.formInitialValues}
                extraRowStyle={row.visibleIf ? 'bg-gray' : null}
            />);

        if (row.visibleIf) {
            if ((typeof row.visibleIf === 'object') && (row.visibleIf.constructor === Array)) {
                // console.log(row.visibleIf);
                let wrappedJsx = jsx;
                row.visibleIf.forEach((item) => {
                    wrappedJsx = <VisibleIf {...item} key={index}>{wrappedJsx}</VisibleIf>;
                })
                return wrappedJsx;
            } else {
                return <VisibleIf {...row.visibleIf} key={index}>{jsx}</VisibleIf>
            }
        } else {
            return jsx;
        }
    };

    return (
        <div className="card mb-3" id={`card-${props.index}`}>
            {props.card.title && <CardHeader title={props.card.title} subTitle={props.card.subTitle} tooltip={props.card.tooltip} titleClass={props.card.titleClass}/>}
            <div className="card-body">
                {props.card.rows.map(renderRow)}
            </div>
        </div>
    );
};

export default React.memo(card);